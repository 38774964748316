import { sanitizeId } from "utils/urls";

import base, { IDetail, IList } from "./base";

export interface IShopsOutput {
  address?: string;
  ccProcessingCharges?: decimalstring;
  city?: string;
  county?: string;
  countyTax?: decimalstring;
  created?: timestamp;
  ein?: string;
  futaTax?: decimalstring;
  hasCcTips?: boolean;
  isActive?: boolean;
  medicareTax?: decimalstring;
  modified?: timestamp;
  name?: string;
  nickname?: string;
  openingDate?: datestamp | null;
  paylocityIdentifier?: string;
  phone?: string;
  posIdentifier?: string | null;
  salesTax?: decimalstring;
  salesTaxId?: string;
  schedulerTax?: decimalstring;
  shopIdentifier?: string;
  socialSecurityTax?: decimalstring;
  state?: string;
  suiNumber?: string;
  suiTax?: decimalstring;
  timezone?: string;
}

export interface IShopsInput extends Required<IShopsOutput> {
  id: identifier;
  schedules: identifier[];
}

export interface IShopSchedulesOutput {
  closingHour?: time | null;
  created?: timestamp;
  minimumLaborPerHour?: decimalstring;
  modified?: timestamp;
  openingHour?: time | null;
  shop?: identifier;
  weekday?: number;
}

export interface IShopSchedulesInput extends Required<IShopSchedulesOutput> {
  id: identifier;
}

export interface IShopsDetail extends IDetail<IShopsInput> {
  includes: {
    schedules: IShopSchedulesInput[];
  };
}

export type IShopsCreate = IShopsDetail;
export type IShopsEdit = IShopsDetail;
export type IShopsList = IList<IShopsInput>;

export const create = (data: IShopsOutput) => base.post<IShopsCreate>("shops/", { json: data });
export const destroy = (id: identifierOrString) => base.deleteEmpty(`shops/${sanitizeId(id)}/`);
export const detail = (id: identifierOrString) =>
  base.get<IShopsDetail>(`shops/${sanitizeId(id)}/`);
export const edit = (id: identifierOrString, data: IShopsOutput) =>
  base.patch<IShopsEdit>(`shops/${sanitizeId(id)}/`, { json: data });
export const list = (page = 1, searchParams: any = {}) =>
  base.list<IShopsList>("shops/", page, -1, { searchParams });

export interface IShopsDashboardActiveEmployees {
  firstName: string;
  id: identifier;
  lastName: string;
}

export interface IShopsReportsData30m {
  activeEmployees: IShopsDashboardActiveEmployees[];
  activeEmployeesLevel: decimalstring;
  isOpen: boolean;
  leadCount: number;
  productivity: decimalstring;
  salesPerHour: decimalstring;
  shop: identifier;
  status: "red" | "green" | "yellow";
}

export interface IShopsReportsDataDaily {
  minimumLabor: decimalstring;
  percentageLabor: decimalstring;
  salesAmount: decimalstring;
  salesPerHour: decimalstring;
  shop: identifier;
  status: "red" | "green" | "yellow";
  totalLabor: decimalstring;
}

export type IShopsReportsDataWeekly = IShopsReportsDataDaily;

export interface IShopsDashboard {
  data: {
    daily: IShopsReportsDataDaily[];
    last30m: IShopsReportsData30m[];
  };

  includes: {
    shops: IShopsInput[];
  };
}

export const dashboard = (date?: datestamp) => {
  const searchParams: any = {};
  if (date) {
    searchParams.date = date;
  }

  return base.get<IShopsDashboard>("shops/dashboard/", { searchParams });
};

export interface IShopsReportsDaily {
  data: IShopsReportsDataDaily[];
  includes: {
    shops: IShopsInput[];
  };
}

export interface IShopsReportsWeekly {
  data: IShopsReportsDataWeekly[];
  includes: {
    shops: IShopsInput[];
  };
}

export const reportsDaily = (date?: datestamp) => {
  const searchParams: any = { type: "daily" };
  if (date) {
    searchParams.date = date;
  }

  return base.get<IShopsReportsDaily>("shops/reports/", { searchParams });
};

export const reportsWeekly = (week?: datestamp) => {
  const searchParams: any = { type: "weekly" };
  if (week) {
    searchParams.date = week;
  }

  return base.get<IShopsReportsWeekly>("shops/reports/", { searchParams });
};

export interface IShopSchedulesDetail extends IDetail<IShopSchedulesInput> {
  includes: {
    shop: IShopsInput;
  };
}

export type IShopSchedulesCreate = IShopSchedulesDetail;
export type IShopSchedulesEdit = IShopSchedulesDetail;
export type IShopSchedulesList = IList<IShopSchedulesInput>;

export const schedulesCreate = (data: IShopSchedulesOutput) =>
  base.post<IShopSchedulesCreate>("shop_schedules/", { json: data });
export const schedulesDestroy = (id: identifierOrString) =>
  base.deleteEmpty(`shop_schedules/${sanitizeId(id)}/`);
export const schedulesDetail = (id: identifierOrString) =>
  base.get<IShopSchedulesDetail>(`shop_schedules/${sanitizeId(id)}/`);
export const schedulesEdit = (id: identifierOrString, data: IShopSchedulesOutput) =>
  base.patch<IShopSchedulesEdit>(`shop_schedules/${sanitizeId(id)}/`, { json: data });
export const schedulesList = (shopId: identifierOrString, page = 1) =>
  base.list<IShopSchedulesList>(`shops/${shopId}/schedules/`, page);
