import { IEmployeesInput, IEmploymentPositionsInput, IEmploymentsInput } from "api/employees";

import base, { IList } from "./base";
import { IShopsInput } from "./shops";

export interface IInvoicesOutput {
  created?: timestamp;
  employeeCode?: string;
  invoiceAt?: timestamp;
  invoiceDate?: datestamp;
  invoiceNumber?: number;
  isVoided?: false;
  modified?: timestamp;
  netSales?: decimalstring;
  position?: identifier;
  shop?: identifier;
  tax?: decimalstring;
  tenderAmount?: decimalstring;
  tenderCount?: number;
}

export interface IInvoicesInput extends Required<IInvoicesOutput> {
  id: identifier;
}

export interface IInvoicesList extends IList<IInvoicesInput> {
  includes: {
    employees: IEmployeesInput[];
    employmentPositions: IEmploymentPositionsInput[];
    employments: IEmploymentsInput[];
    shops: IShopsInput[];
  };
}

export const list = (page = 1, searchParams: any = {}) =>
  base.list<IInvoicesList>("invoices/", page, 0, { searchParams });
