import { flow, getRoot, Instance, types } from "mobx-state-tree";

import api from "api";
import {
  IEmployeeNotesCreate,
  IEmployeeNotesDetail,
  IEmployeeNotesEdit,
  IEmployeeNotesList,
  IEmployeeNotesOutput,
  IEmployeesCreate,
  IEmployeesDetail,
  IEmployeesEdit,
  IEmployeesList,
  IEmployeesOutput,
  IEmploymentPositionsCreate,
  IEmploymentPositionsDetail,
  IEmploymentPositionsEdit,
  IEmploymentPositionsOutput,
  IEmploymentsCreate,
  IEmploymentsDetail,
  IEmploymentsEdit,
  IEmploymentsList,
  IEmploymentsOutput,
} from "api/employees";
import { mergeToMap } from "utils/stores";
import { sanitizeIdString } from "utils/urls";

import { IEmployee, IEmployeeNote, IEmployment, IEmploymentPosition, IRootStore } from ".";
import { Employee, EmployeeNote, Employment, EmploymentPosition } from "./models";

const EmployeeStore = types
  .model("EmployeeStore", {
    all: types.map(Employee),
    employmentPositions: types.map(EmploymentPosition),
    employments: types.map(Employment),
    notes: types.map(EmployeeNote),
    total: types.optional(types.number, 0),
    totalNotes: types.optional(types.number, 0),
  })
  .actions((self) => {
    const merge = (data: any[]) => {
      return mergeToMap<IEmployee>(data, Employee, self.all);
    };

    const mergeEmploymentPositions = (data: any[]) => {
      return mergeToMap<IEmploymentPosition>(data, EmploymentPosition, self.employmentPositions);
    };

    const mergeEmployments = (data: any[]) => {
      return mergeToMap<IEmployment>(data, Employment, self.employments);
    };

    const mergeNotes = (data: any[]) => {
      return mergeToMap<IEmployeeNote>(data, EmployeeNote, self.notes);
    };

    return {
      merge,
      mergeEmploymentPositions,
      mergeEmployments,
      mergeNotes,

      create: flow(function* (output: IEmployeesOutput) {
        const response: IEmployeesCreate = yield api.employees.create(output);

        const [employee] = merge([response.data]);
        return employee;
      }),

      destroy: flow(function* (id: identifierOrString) {
        yield api.employees.destroy(id);

        self.all.delete(id.toString());
      }),

      edit: flow(function* (id: identifierOrString, output: IEmployeesOutput) {
        const response: IEmployeesEdit = yield api.employees.edit(id, output);

        merge(response.includes.employees);
        mergeNotes(response.includes.notes);
        mergeEmployments(response.includes.employments);
        mergeEmploymentPositions(response.includes.employmentPositions);
        getRoot<IRootStore>(self).shops.merge(response.includes.shops);

        const [employee] = merge([response.data]);
        return employee;
      }),

      detail: flow(function* (id: identifierOrString) {
        const response: IEmployeesDetail = yield api.employees.detail(id);

        merge(response.includes.employees);
        mergeNotes(response.includes.notes);
        mergeEmployments(response.includes.employments);
        mergeEmploymentPositions(response.includes.employmentPositions);
        getRoot<IRootStore>(self).shops.merge(response.includes.shops);

        const [employee] = merge([response.data]);
        return employee;
      }),

      list: flow(function* (page = 1, searchParams: any = {}) {
        const response: IEmployeesList = yield api.employees.list(page, searchParams);

        self.total = response.count;

        getRoot<IRootStore>(self).shops.merge(response.includes.shops);
        mergeEmployments(response.includes.employments);
        merge(response.includes.employees);

        return merge(response.data);
      }),

      notesCreate: flow(function* (output: IEmployeeNotesOutput) {
        const response: IEmployeeNotesCreate = yield api.employees.notesCreate(output);

        const [note] = mergeNotes([response.data]);

        merge([response.includes.employee]);

        return note;
      }),

      notesDestroy: flow(function* (id: identifierOrString) {
        yield api.employees.notesDestroy(id);

        const note = self.notes.get(id.toString());
        if (note) note.destroy();
      }),

      notesDetail: flow(function* (id: identifierOrString) {
        const response: IEmployeeNotesDetail = yield api.employees.notesDetail(id);

        const [note] = mergeNotes([response.data]);

        merge([response.includes.employee]);

        return note;
      }),

      notesEdit: flow(function* (id: identifierOrString, output: IEmployeeNotesOutput) {
        const response: IEmployeeNotesEdit = yield api.employees.notesEdit(id, output);

        const [note] = mergeNotes([response.data]);

        return note;
      }),

      notesList: flow(function* (employeeId: identifierOrString, page = 1) {
        const response: IEmployeeNotesList = yield api.employees.notesList(employeeId, page);

        self.totalNotes = response.count;

        return mergeNotes(response.data);
      }),

      employmentsCreate: flow(function* (
        employeeId: identifierOrString,
        output: IEmploymentsOutput
      ) {
        const response: IEmploymentsCreate = yield api.employees.employmentsCreate(
          employeeId,
          output
        );

        merge([response.includes.employee]);
        mergeEmploymentPositions(response.includes.employmentPositions);
        getRoot<IRootStore>(self).shops.merge([response.includes.shop]);

        const [employment] = mergeEmployments([response.data]);
        return employment;
      }),

      employmentsDestroy: flow(function* (id: identifierOrString) {
        yield api.employees.employmentsDestroy(id);

        self.employments.delete(id.toString());
      }),

      employmentsDetail: flow(function* (id: identifierOrString) {
        const response: IEmploymentsDetail = yield api.employees.employmentsDetail(id);

        const [employment] = mergeEmployments([response.data]);

        merge([response.includes.employee]);
        mergeEmploymentPositions(response.includes.employmentPositions);
        getRoot<IRootStore>(self).shops.merge([response.includes.shop]);

        return employment;
      }),

      employmentsEdit: flow(function* (id: identifierOrString, output: IEmploymentsOutput) {
        const response: IEmploymentsEdit = yield api.employees.employmentsEdit(id, output);

        merge([response.includes.employee]);
        mergeEmploymentPositions(response.includes.employmentPositions);
        getRoot<IRootStore>(self).shops.merge([response.includes.shop]);

        const [employment] = mergeEmployments([response.data]);
        return employment;
      }),

      employmentsList: flow(function* (shopId: identifierOrString) {
        const response: IEmploymentsList = yield api.employees.employmentsList(shopId);

        const employments = mergeEmployments(response.data);

        merge(response.includes.employees);
        mergeEmploymentPositions(response.includes.employmentPositions);
        getRoot<IRootStore>(self).shops.merge(response.includes.shops);

        return employments;
      }),

      positionsCreate: flow(function* (output: IEmploymentPositionsOutput) {
        const response: IEmploymentPositionsCreate = yield api.employees.employmentPositionsCreate(
          output
        );

        const [employmentPosition] = mergeEmploymentPositions([response.data]);

        return employmentPosition;
      }),

      positionsDetail: flow(function* (id: identifierOrString) {
        const response: IEmploymentPositionsDetail = yield api.employees.employmentPositionsDetail(
          id
        );

        mergeEmployments([response.includes.employment]);

        const shopStore = getRoot<IRootStore>(self).shops;
        shopStore.merge(response.includes.shops);

        const [employmentPosition] = mergeEmploymentPositions([response.data]);
        return employmentPosition;
      }),

      positionsDestroy: flow(function* (id: identifierOrString) {
        yield api.employees.employmentPositionsDestroy(id);

        const employmentPosition = self.employmentPositions.get(id.toString());
        if (employmentPosition) employmentPosition.destroy();
      }),

      positionsEdit: flow(function* (id: identifierOrString, output: IEmploymentPositionsOutput) {
        const response: IEmploymentPositionsEdit = yield api.employees.employmentPositionsEdit(
          id,
          output
        );

        const [employmentPosition] = mergeEmploymentPositions([response.data]);

        return employmentPosition;
      }),
    };
  })
  .views((self) => ({
    get: (id: identifierOrString) => {
      return self.all.get(sanitizeIdString(id));
    },

    getEmployment: (id: identifierOrString) => {
      return self.employments.get(sanitizeIdString(id));
    },

    getNote: (id: identifierOrString) => {
      return self.notes.get(sanitizeIdString(id));
    },

    getPosition: (id: identifierOrString) => {
      return self.employmentPositions.get(sanitizeIdString(id));
    },
  }));

export interface IEmployeeStore extends Instance<typeof EmployeeStore> {}

export default EmployeeStore;
