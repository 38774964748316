import { message } from "antd";
import { HTTPError, TimeoutError } from "ky";

import * as auth from "./auth";
import * as employees from "./employees";
import * as housekeeping from "./housekeeping";
import * as labors from "./labors";
import * as reports from "./reports";
import * as sales from "./sales";
import * as scheduler from "./scheduler";
import * as shops from "./shops";

export const config = {
  defaultLimit: 25,
  token: "",
};

export interface IValidationErrors {
  [key: string]: string[];
}

export interface IErrorResponse {
  code:
    | "authentication_failed"
    | "not_authenticated"
    | "deletion_error"
    | "internal_error"
    | "missing_error"
    | "network_error"
    | "permission_error"
    | "syntax_error"
    | "timeout_error"
    | "validation_error";
  description: string;
  detail?: IValidationErrors | string | null;
}

async function handleError(error: Error): Promise<IErrorResponse> {
  let errorResponse: IErrorResponse;

  if (error instanceof HTTPError) {
    errorResponse = await error.response.json();
  } else if (error instanceof TimeoutError) {
    errorResponse = {
      code: "timeout_error",
      description: error.message,
    };

    message.error("Request to API server has timed out. Please try again.");
  } else if (error instanceof TypeError) {
    errorResponse = {
      code: "network_error",
      description: error.message,
    };

    message.error("API server is currently inaccessible. Please try again later.");
  } else {
    errorResponse = {
      code: "syntax_error",
      description: error.message,
    };

    message.error("Error encountered. Please check logs for more details.");
  }

  console.error(errorResponse);

  return errorResponse;
}

export default {
  auth,
  config,
  employees,
  handleError,
  housekeeping,
  labors,
  reports,
  sales,
  scheduler,
  shops,
};
