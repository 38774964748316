import { Location } from "history";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function getPage(location: Location) {
  const params = new URLSearchParams(location.search);

  return Number(params.get("page") || 1);
}

export function usePage() {
  const location = useLocation();
  const [page, setPage] = useState<number>(getPage(location));

  useEffect(() => setPage(getPage(location)), [location]);

  return page;
}
