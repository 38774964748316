import { flow, Instance, types } from "mobx-state-tree";
import { groupBy } from "ramda";

import api from "api";
import {
  IShopSchedulesCreate,
  IShopSchedulesDetail,
  IShopSchedulesEdit,
  IShopSchedulesList,
  IShopSchedulesOutput,
  IShopsCreate,
  IShopsDashboard,
  IShopsDetail,
  IShopsEdit,
  IShopsList,
  IShopsOutput,
  IShopsReportsDaily,
  IShopsReportsWeekly,
} from "api/shops";
import { mergeToMap } from "utils/stores";
import { sanitizeIdString } from "utils/urls";

import {
  IShop,
  IShopDashboardDaily,
  IShopSchedule,
  Shop,
  ShopDashboard30m,
  ShopDashboardDaily,
  ShopDashboardWeekly,
  ShopSchedule,
} from "./models";

const ShopsStore = types
  .model("ShopsStore", {
    all: types.map(Shop),
    dashboards30m: types.array(ShopDashboard30m),
    dashboardsDaily: types.array(ShopDashboardDaily),
    dashboardsWeekly: types.array(ShopDashboardWeekly),
    schedules: types.map(ShopSchedule),
    total: types.optional(types.number, 0),
    totalSchedules: types.optional(types.number, 0),
  })
  .actions((self) => {
    const merge = (data: any[]) => {
      return mergeToMap<IShop>(data, Shop, self.all);
    };

    const mergeSchedules = (data: any[]) => {
      return mergeToMap<IShopSchedule>(data, ShopSchedule, self.schedules);
    };

    return {
      merge,
      mergeSchedules,

      create: flow(function* (output: IShopsOutput) {
        const response: IShopsCreate = yield api.shops.create(output);

        const [shop] = merge([response.data]);

        return shop;
      }),

      dashboard: flow(function* (date?: datestamp) {
        const response: IShopsDashboard = yield api.shops.dashboard(date);

        const dailyDashboard = response.data.daily.map((data) => ShopDashboardDaily.create(data));
        const daily30m = response.data.last30m.map((data) => ShopDashboard30m.create(data));

        self.dashboardsDaily.replace(dailyDashboard);
        self.dashboards30m.replace(daily30m);

        merge(response.includes.shops);

        const byState = groupBy((daily: IShopDashboardDaily) => daily?.shop?.state || "Others");
        return {
          daily: byState(dailyDashboard),
          every30m: daily30m,
        };
      }),

      destroy: flow(function* (id: identifierOrString) {
        yield api.shops.destroy(id);

        self.all.delete(id.toString());
      }),

      detail: flow(function* (id: identifierOrString) {
        const response: IShopsDetail = yield api.shops.detail(id);

        const [shop] = merge([response.data]);

        mergeSchedules(response.includes.schedules);

        return shop;
      }),

      edit: flow(function* (id: identifierOrString, output: IShopsOutput) {
        const response: IShopsEdit = yield api.shops.edit(id, output);

        const [shop] = merge([response.data]);

        return shop;
      }),

      list: flow(function* (page = 1, searchParams: any = {}) {
        const response: IShopsList = yield api.shops.list(page, searchParams);

        self.total = response.count;

        return merge(response.data);
      }),

      reportsDaily: flow(function* (date?: datestamp) {
        const response: IShopsReportsDaily = yield api.shops.reportsDaily(date);

        const dailyDashboard = response.data.map((data) => ShopDashboardDaily.create(data));

        merge(response.includes.shops);

        self.dashboardsDaily.replace(dailyDashboard);
      }),

      reportsWeekly: flow(function* (week?: string) {
        const response: IShopsReportsWeekly = yield api.shops.reportsWeekly(week);

        const weeklyDashboard = response.data.map((data) => ShopDashboardWeekly.create(data));

        merge(response.includes.shops);

        self.dashboardsWeekly.replace(weeklyDashboard);
      }),

      schedulesCreate: flow(function* (output: IShopSchedulesOutput) {
        const response: IShopSchedulesCreate = yield api.shops.schedulesCreate(output);

        const [schedule] = mergeSchedules([response.data]);

        return schedule;
      }),

      schedulesDestroy: flow(function* (id: identifierOrString) {
        yield api.shops.schedulesDestroy(id);

        const schedule = self.schedules.get(id.toString());
        if (schedule) schedule.destroy();
      }),

      schedulesDetail: flow(function* (id: identifierOrString) {
        const response: IShopSchedulesDetail = yield api.shops.schedulesDetail(id);

        const [schedule] = mergeSchedules([response.data]);

        merge([response.includes.shop]);

        return schedule;
      }),

      schedulesEdit: flow(function* (id: identifierOrString, output: IShopSchedulesOutput) {
        const response: IShopSchedulesEdit = yield api.shops.schedulesEdit(id, output);

        const [schedule] = mergeSchedules([response.data]);

        return schedule;
      }),

      schedulesList: flow(function* (shopId: identifierOrString, page = 1) {
        const response: IShopSchedulesList = yield api.shops.schedulesList(shopId, page);

        self.totalSchedules = response.count;

        return mergeSchedules(response.data);
      }),
    };
  })
  .views((self) => ({
    get dashboards30mSorted() {
      return self.dashboards30m
        .slice()
        .sort((a, b) => (a.shop.name || "").localeCompare(b.shop.name || ""));
    },

    get dashboardsDailySorted() {
      return self.dashboardsDaily.slice().sort((a, b) => {
        if (!a.shop) return 1;
        if (!b.shop) return -1;

        return (a.shop.name || "").localeCompare(b.shop.name || "");
      });
    },

    get dashboardsWeeklySorted() {
      return self.dashboardsWeekly
        .slice()
        .sort((a, b) => (a.shop.name || "").localeCompare(b.shop.name || ""));
    },

    get: (id: identifierOrString) => {
      return self.all.get(sanitizeIdString(id));
    },

    getSchedule: (id: identifierOrString) => {
      return self.schedules.get(sanitizeIdString(id));
    },
  }));

export interface IShopsStore extends Instance<typeof ShopsStore> {}

export default ShopsStore;
