import base, { IDetail, IList } from "./base";

export type ITreatdataReportsDateRanges = Array<[datestamp, datestamp]>;
export type ITreatdataReportsType = "invoice" | "labor";

export interface ITreatdataReportsOutput {
  dateRangesAll: ITreatdataReportsDateRanges;
  type: ITreatdataReportsType;
}

export interface ITreatdataReportsInput extends Required<ITreatdataReportsOutput> {
  author: identifier;
  created: timestamp;
  dateRangesDone: ITreatdataReportsDateRanges;
  file: string | null;
  filename: string | null;
  id: identifier;
  isOngoing: boolean;
  modified: timestamp;
  progress: number;
  uploadedAt: timestamp | null;
}

export interface ITreatdataReportsDetail extends IDetail<ITreatdataReportsInput> {}

export interface ITreatdataReportsList extends IList<ITreatdataReportsInput> {
  includes: {
    inProgress: number;
  };
}

export type ITreatdataReportsCreate = ITreatdataReportsDetail;

export const treatdataReportsCreate = (
  type: ITreatdataReportsType,
  startDate: datestamp,
  endDate: datestamp
) =>
  base.post<ITreatdataReportsCreate>("reports/treatdata/", {
    json: {
      endDate,
      startDate,
      type,
    },
  });

export const treatdataReportsDetail = (id: identifierOrString) =>
  base.get<ITreatdataReportsDetail>(`reports/treatdata/${id}/`);

export const treatdataReportsList = (page = 1, searchParams: any = {}) =>
  base.list<ITreatdataReportsList>("reports/treatdata/", page, -1, { searchParams });
