import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popconfirm, Table } from "antd";
import { PaginationConfig } from "antd/lib/pagination";
import { SortOrder, SorterResult } from "antd/lib/table";
import { useSearchParams } from "hooks";
import { camelize, decamelize } from "humps";
import { has } from "lodash";
import { IModelReflectionPropertiesData } from "mobx-state-tree";
import React, { MouseEvent, useCallback, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { IDecimal, IMoney, IPercentage } from "stores/types";
import styled from "styled-components";

import { Loadable } from "components";
import {
  renderBoolean,
  renderDate,
  renderDateTime,
  renderDecimal,
  renderMoney,
  renderPercentage,
  renderPhone,
  titleize,
} from "utils/formatting";
import { getSearchParams } from "utils/urls";

const Action = styled.span`
  color: #1890ff;
  cursor: pointer;
  margin-right: 5px;
`;

const StyledTable = styled<any>(Table)`
  .ant-table-row {
    cursor: ${(props) => (props.disableShow ? "auto" : "pointer")};
  }

  .ant-table-row > td:empty:before {
    content: "—";
  }

  .ant-table-row a:hover,
  .ant-table-row a:hover * {
    text-decoration: underline;
  }

  .ant-table-row > td > span {
    cursor: auto;

    &:empty:before {
      content: "—";
    }
  }
`;

const TableContent = styled.span`
  cursor: auto;

  &:empty:before {
    content: "—";
  }
`;

type Alignment = "left" | "center" | "right";

export type ModelTableActions = Array<"delete" | "edit" | "unapprove">;

export interface IModelTable<T = any, K = Extract<keyof T, string>> {
  actions?: ModelTableActions;
  approvedKey?: string;
  className?: string;
  columns: Array<IModelTableColumn<K> | K>;
  dataSource: any[];
  defaultOrdering?: string;
  disableShow?: boolean;
  isLoading?: boolean;
  labelRenderer?: (record: any) => string;
  labelKey?: K;
  model: IModelReflectionPropertiesData;
  onDelete?: (record: any) => void;
  onUnapprove?: (record: any) => void;
  onSort?: (ordering: string) => void;
  ordering?: string;
  rowKey?: K;
  sortable?: string[];
  url?: string;
}

export interface IModelTableColumn<T = string> {
  align?: Alignment;
  dataIndex: T | "actions";
  key?: string;
  render?: (value: any, record: any) => React.ReactNode;
  sorter?: (a: any, b: any, sortOrder: SortOrder) => number;
  title?: React.ReactNode;
}

const renderers: { [key: string]: any } = {
  Date: renderDate,
  DateTime: renderDateTime,
  Decimal: renderDecimal,
  Money: renderMoney,
  Percentage: renderPercentage,
  Phone: renderPhone,
  boolean: renderBoolean,
};

const rendererDefault = (value: any) => value;

function getAlignment(model: IModelReflectionPropertiesData, dataIndex: string): Alignment {
  const property = model.properties[dataIndex];
  if (!property || !property.name) return "left";

  if (["Date", "DateTime", "Decimal", "Money", "Percentage", "number"].includes(property.name)) {
    return "right";
  }

  return "left";
}

function getSorter(model: IModelReflectionPropertiesData, dataIndex: string) {
  const property = model.properties[dataIndex];
  if (!property || !property.name) return true;

  switch (property.name) {
    case "Decimal":
      return (a: any, b: any) => {
        if (!a) return -1;
        if (!b) return 1;

        const aProp: IDecimal | null = a[dataIndex];
        const bProp: IDecimal | null = b[dataIndex];

        if (!aProp) return -1;
        if (!bProp) return 1;

        return aProp.cmp(bProp);
      };

    case "Money":
      return (a: any, b: any) => {
        if (!a) return -1;
        if (!b) return 1;

        const aProp: IMoney | null = a[dataIndex];
        const bProp: IMoney | null = b[dataIndex];

        if (!aProp) return -1;
        if (!bProp) return 1;

        return aProp.cmp(bProp);
      };

    case "Percentage":
      return (a: any, b: any) => {
        if (!a) return -1;
        if (!b) return 1;

        const aProp: IPercentage | null = a[dataIndex];
        const bProp: IPercentage | null = b[dataIndex];

        if (!aProp) return -1;
        if (!bProp) return 1;

        return aProp.cmp(bProp);
      };

    case "number":
      return (a: any, b: any) => {
        if (!a) return -1;
        if (!b) return 1;

        const aProp: number | null = a[dataIndex];
        const bProp: number | null = b[dataIndex];

        if (!aProp) return -1;
        if (!bProp) return 1;

        if (aProp < bProp) {
          return -1;
        } else if (aProp === bProp) {
          return 0;
        } else {
          return 1;
        }
      };

    default:
      return true;
  }
}

export function ModelTable<T>({
  actions = [],
  approvedKey,
  className,
  columns,
  dataSource,
  defaultOrdering = "",
  disableShow,
  isLoading,
  labelKey,
  labelRenderer,
  model,
  onDelete = () => {},
  onUnapprove = () => {},
  onSort,
  ordering,
  rowKey,
  sortable = [],
  url,
}: IModelTable<T>) {
  const [orderingCurrent, setOrderingCurrent] = useState("");
  const history = useHistory();
  const searchParams = useSearchParams<any>();

  const getModelTableColumnRender = useCallback(
    (column: string) => {
      const property = model.properties[column];
      if (!property) return undefined;

      return (value: any) => {
        const renderer = renderers[property.name] || rendererDefault;

        return <TableContent>{renderer(value)}</TableContent>;
      };
    },
    [model.properties]
  );

  const getLabel = (record: any) => {
    let label = "";
    if (labelRenderer) label = labelRenderer(record);
    if (labelKey) label = record[labelKey];

    return label || "this";
  };

  const isApproved = (record: any) =>
    !!approvedKey && has(record, approvedKey) && record[approvedKey];

  const orderingActual = ordering === undefined && searchParams ? searchParams.ordering : ordering;
  let orderingFinal =
    (orderingActual == null ? orderingCurrent : orderingActual) || defaultOrdering;
  if (orderingFinal.startsWith("-")) {
    orderingFinal = `-${camelize(orderingFinal)}`;
  } else {
    orderingFinal = camelize(orderingFinal);
  }

  const modelTableColumns = columns.map((column) => {
    let align: Alignment | undefined;
    let dataIndex: string;
    let key;
    let render;
    let sortOrder;
    let sorter;
    let title;

    if (typeof column === "string") {
      dataIndex = column;
      key = column;
      title = column === "id" ? "#" : titleize(column);
    } else {
      align = column.align;
      dataIndex = column.dataIndex;
      key = column.key;
      render = column.render;
      sorter = column.sorter;
      title = column.title || titleize(column.dataIndex);
    }

    render = render || getModelTableColumnRender(dataIndex);

    if (align === undefined) {
      align = getAlignment(model, dataIndex);
    }

    const sorterKey = key || dataIndex;

    const isSortable = sortable.includes(key || dataIndex);
    if (!isSortable) {
      sorter = undefined;
    } else if (sorter === undefined) {
      if (!url) {
        sorter = getSorter(model, dataIndex);
      } else {
        sorter = true;
      }
    }

    if (orderingFinal === sorterKey) {
      sortOrder = "ascend";
    } else if (orderingFinal === `-${sorterKey}`) {
      sortOrder = "descend";
    }

    return {
      align,
      dataIndex,
      key,
      render,
      sortOrder,
      sorter,
      title,
    };
  });

  if (actions.length > 0) {
    modelTableColumns.push({
      key: "actions",
      render: (text: any, record: any) =>
        actions.map((action) => {
          if (action === "edit") {
            return (
              <Action key={action} title="Edit">
                <Link to={`${url}${record[rowKey || "id"]}/edit`}>
                  <FontAwesomeIcon icon="edit" />
                </Link>
              </Action>
            );
          } else if (action === "delete") {
            return (
              <Action key={action} title="Delete">
                <Popconfirm
                  disabled={isLoading}
                  key="delete"
                  onConfirm={() => onDelete(record)}
                  title={`Are you sure you want to delete “${getLabel(record)}”?`}
                >
                  <FontAwesomeIcon icon="trash-alt" />
                </Popconfirm>
              </Action>
            );
          } else if (action === "unapprove" && isApproved(record)) {
            return (
              <Action key={action} title="Unapprove">
                <Popconfirm
                  disabled={isLoading}
                  key="unapprove"
                  onConfirm={() => onUnapprove(record)}
                  title={`Are you sure you want to unapprove “${getLabel(record)}”?`}
                >
                  <FontAwesomeIcon icon="unlock" />
                </Popconfirm>
              </Action>
            );
          }

          return null;
        }),
      title: "Actions",
    } as any);
  }

  const handleChange = (
    pagination: PaginationConfig,
    filters: Record<keyof T, string[]>,
    sorter: SorterResult<T>
  ) => {
    if (sorter) {
      const orderKey = decamelize(sorter.columnKey);

      let orderingCurrentNew = "";
      if (sorter.order === "descend") {
        orderingCurrentNew = `-${orderKey}`;
      } else {
        orderingCurrentNew = orderKey;
      }

      setOrderingCurrent(orderingCurrentNew);

      if (onSort) {
        onSort(orderingCurrentNew);
      } else if (url) {
        const searchParamsNew = { ...searchParams, ordering: orderingCurrentNew };
        history.push(`${url}?${getSearchParams(searchParamsNew)}`);
      }
    }
  };

  const handleRow = (record: any) => ({
    onClick(event: MouseEvent) {
      if (url && !disableShow && event.currentTarget.tagName === "TD") {
        history.push(`${url}${record[rowKey || "id"]}`);
      }
    },
  });

  return (
    <Loadable spinning={isLoading}>
      <StyledTable
        className={className}
        columns={modelTableColumns}
        dataSource={dataSource}
        disableShow={disableShow || !url}
        onChange={handleChange}
        onRow={handleRow}
        pagination={false}
        rowKey={rowKey || "id"}
      />
    </Loadable>
  );
}
