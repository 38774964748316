import { Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import React, { ReactNode, useState } from "react";
import styled from "styled-components";

import { Loadable, Pagination } from "components";

const TableContainer = styled.div`
  .ant-pagination {
    display: none;
  }
`;

const TableTitle = styled.h3`
  margin: 0;
`;

interface IProps<T> {
  className?: string;
  columns: Array<ColumnProps<T>>;
  dataLabel: string;
  dataSource: T[];
  footerView?: ReactNode;
  headerView?: ReactNode;
  isLoading?: boolean;
  onPageChange?: (page: number, pageSize?: number) => void;
  page?: number;
  pageSize?: number;
  rowKey?: string | ((record: T, index: number) => string);
  title?: string;
}

export function DataTable<T>({
  className,
  columns,
  dataLabel,
  dataSource,
  footerView,
  headerView,
  isLoading = false,
  onPageChange,
  page,
  pageSize = 10,
  rowKey,
  title,
}: IProps<T>) {
  const [pageInternal, setPageInternal] = useState(1);

  const handlePagination = (pageInternalNew: number) => {
    setPageInternal(pageInternalNew);
  };

  let headerViewActual = headerView;
  if (headerViewActual === undefined) {
    headerViewActual = title ? <TableTitle>{title}</TableTitle> : null;
  }

  const pageActual = page === undefined ? pageInternal : page;
  const onPageChangeActual = onPageChange === undefined ? handlePagination : onPageChange;

  return (
    <div className={className}>
      <Pagination
        label={dataLabel}
        leftView={headerViewActual}
        limit={pageSize}
        onChange={onPageChangeActual}
        page={pageActual}
        total={dataSource.length}
      />

      <TableContainer>
        <Loadable spinning={isLoading}>
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={{ pageSize, current: pageActual }}
            rowKey={rowKey}
          />
        </Loadable>
      </TableContainer>

      <Pagination
        label={dataLabel}
        leftView={footerView}
        limit={pageSize}
        onChange={onPageChangeActual}
        page={pageActual}
        total={dataSource.length}
      />
    </div>
  );
}
