import { Decimal as DecimalJs } from "decimal.js";
import { types } from "mobx-state-tree";

export const Percentage = types.custom<string | null, DecimalJs | null>({
  name: "Percentage",
  fromSnapshot(snapshot) {
    if (!snapshot) return null;

    return new DecimalJs(snapshot);
  },
  toSnapshot(value) {
    if (!value) return null;

    return value.toFixed(4);
  },
  isTargetType(value: any) {
    return DecimalJs.isDecimal(value);
  },
  getValidationMessage(snapshot) {
    if (DecimalJs.isDecimal(snapshot)) {
      return "Invalid DecimalJs object";
    }
    return "";
  },
});

export type IPercentage = DecimalJs | null;
