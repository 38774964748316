import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spin } from "antd";
import React from "react";
import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  animation: ${rotate} 0.8s ease-out infinite;
`;

const FullScreenSpin = styled(Spin)`
  && {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }
`;

const TopScreenSpin = styled(Spin)`
  && {
    align-items: flex-start;
    display: flex;
    margin-top: 20px;
    justify-content: center;
  }
`;

export interface ILoadable {
  fullscreen?: boolean;
  spinning?: boolean;
  topScreen?: boolean;
}

export const Loadable: React.FC<ILoadable> = ({
  children,
  fullscreen,
  spinning = true,
  topScreen,
}) => {
  if (fullscreen) {
    return (
      <FullScreenSpin
        children={children}
        indicator={<StyledIcon icon={["fad", "spinner-third"]} spin={spinning} />}
        size="large"
        spinning={spinning}
      />
    );
  }

  if (topScreen) {
    return (
      <TopScreenSpin
        children={children}
        indicator={<StyledIcon icon={["fad", "spinner-third"]} spin={spinning} />}
        size="large"
        spinning={spinning}
      />
    );
  }

  return (
    <Spin
      children={children}
      indicator={<StyledIcon icon={["fad", "spinner-third"]} spin={spinning} />}
      size="large"
      spinning={spinning}
    />
  );
};
