import { EmployeeNoteType, EmploymentPositionRole, Gender, PayType } from "stores";
import { sanitizeId } from "utils/urls";

import base, { IDetail, IList } from "./base";
import { IShopsInput } from "./shops";

export interface IEmployeesOutput {
  birthDate?: datestamp | null;
  ethnicity?: string;
  firstName?: string;
  gender?: Gender;
  homeAddress1?: string;
  homeAddress2?: string;
  homeCity?: string;
  homeCountry?: string;
  homeCounty?: string;
  homeEmail?: string;
  homeLandlinePhone?: string;
  homeMobilePhone?: string;
  homeState?: string;
  homeZip?: string;
  i9VerifiedDate?: datestamp | null;
  invoiceIdentifier?: string;
  lastName?: string;
  middleName?: string;
  ssn?: string;
  ssnVerifiedDate?: datestamp | null;
  uscisNumber?: string;
  workAddress1?: string;
  workAddress2?: string;
  workAuthorization?: string;
  workCity?: string;
  workCountry?: string;
  workCounty?: string;
  workEmail?: string;
  workLandlinePhone?: string;
  workMobilePhone?: string;
  workState?: string;
  workZip?: string;
}

export interface IEmployeesInput extends Required<IEmployeesOutput> {
  created: timestamp;
  employments: identifier[];
  fromPaylocity: boolean;
  id: identifier;
  modified: timestamp;
  shops: identifier[];
  supervisors: identifier[];
}

export interface IEmployeeNotesOutput {
  author?: identifier;
  comment?: string;
  employee?: identifier;
  performance?: number;
  type?: EmployeeNoteType;
}

export interface IEmployeeNotesInput extends Required<IEmployeeNotesOutput> {
  created: timestamp;
  id: identifier;
  modified: timestamp;
}

export interface IEmploymentsOutput {
  employee?: identifier;
  employeeIdentifier?: string;
  hireDate?: datestamp | null;
  leaveDate?: datestamp | null;
  paylocityIdentifier?: string;
  shop?: identifier;
  status?: string;
  suiState?: string;
  terminationDate?: datestamp | null;
}

export interface IEmploymentsInput extends Required<IEmploymentsOutput> {
  created: timestamp;
  id: identifier;
  modified: timestamp;
  positions: identifier[];
}

export interface IEmploymentPositionsOutput {
  employment?: identifier;
  isActive?: boolean;
  name?: EmploymentPositionRole | string;
  payRate?: decimalstring;
  payType?: PayType;
  posIdentifier?: string;
  shops?: identifier[];
}

export interface IEmploymentPositionsInput extends Required<IEmploymentPositionsOutput> {
  created: timestamp;
  id: identifier;
  modified: timestamp;
}

export interface IEmployeesDetail extends IDetail<IEmployeesInput> {
  includes: {
    employees: IEmployeesInput[];
    employmentPositions: IEmploymentPositionsInput[];
    employments: IEmploymentsInput[];
    notes: IEmployeeNotesInput[];
    shops: IShopsInput[];
  };
}

export type IEmployeesCreate = IEmployeesDetail;
export type IEmployeesEdit = IEmployeesDetail;

export interface IEmployeesList extends IList<IEmployeesInput> {
  includes: {
    employees: IEmployeesInput[];
    employments: IEmploymentsInput[];
    shops: IShopsInput[];
  };
}

export interface IEmployeesImport {
  deactivated: number;
  rowsInvalid: {
    errorSaving: any[];
    invalidRole: any[];
    missingEmployeeId: any[];
    missingShop: any[];
  };
  data: IEmployeesInput[];
}

export interface IEmployeesSuggestions {
  data: string[];
}

export const create = (data: IEmployeesOutput) =>
  base.post<IEmployeesCreate>("employees/", { json: data });
export const destroy = (id: identifierOrString) => base.deleteEmpty(`employees/${sanitizeId(id)}/`);
export const detail = (id: identifierOrString) =>
  base.get<IEmployeesDetail>(`employees/${sanitizeId(id)}/`);
export const edit = (id: identifierOrString, data: IEmployeesOutput) =>
  base.patch<IEmployeesEdit>(`employees/${sanitizeId(id)}/`, { json: data });
export const list = (page = 1, searchParams: any = {}) =>
  base.list<IEmployeesList>("employees/", page, 0, { searchParams });

export const importCsv = (file: any) =>
  base.post<IEmployeesImport>("employees/import/", {
    body: file,
    headers: { "Content-Type": "text/csv" },
  });

export const suggestions = (params: any = {}) =>
  base.get<IEmployeesSuggestions>("employees/suggestions/", { searchParams: params });

export interface IEmployeesUnlinkedPosId {
  employee: string;
  fromInvoice: number;
  fromLabor: number;
  pos: string;
}

export interface IEmployeesUnlinkedPosIdsList {
  count: number;
  data: IEmployeesUnlinkedPosId[];
}

export const unlinkedPosIds = (
  searchParams: { shopId?: identifierOrString; week?: string } = {}
) => {
  const searchParamsActual: any = { ...searchParams };
  if (!searchParamsActual.shopId) delete searchParamsActual.shopId;
  if (!searchParamsActual.week) delete searchParamsActual.week;

  return base.get<IEmployeesUnlinkedPosIdsList>("employees/unlinked_pos_ids/", {
    searchParams: searchParamsActual,
  });
};

export const unlinkedPosIdsCsv = () => base.getEmpty("employees/unlinked_pos_ids_csv/");

export interface IEmployeeNotesDetail extends IDetail<IEmployeeNotesInput> {
  includes: {
    employee: IEmployeesInput;
  };
}

export type IEmployeeNotesCreate = IEmployeeNotesDetail;
export type IEmployeeNotesEdit = IEmployeeNotesDetail;
export type IEmployeeNotesList = IList<IEmployeeNotesInput>;

export const notesCreate = (data: IEmployeeNotesOutput) =>
  base.post<IEmployeeNotesCreate>("employee_notes/", { json: data });
export const notesDestroy = (id: identifierOrString) =>
  base.deleteEmpty(`employee_notes/${sanitizeId(id)}/`);
export const notesDetail = (id: identifierOrString) =>
  base.get<IEmployeeNotesDetail>(`employee_notes/${sanitizeId(id)}/`);
export const notesEdit = (id: identifierOrString, data: IEmployeeNotesOutput) =>
  base.patch<IEmployeeNotesEdit>(`employee_notes/${sanitizeId(id)}/`, {
    json: data,
  });
export const notesList = (employeeId: identifierOrString, page = 1) =>
  base.list<IEmployeeNotesList>(`employees/${sanitizeId(employeeId)}/notes/`, page);

export interface IEmploymentsDetail extends IDetail<IEmploymentsInput> {
  includes: {
    employee: IEmployeesInput;
    employmentPositions: IEmploymentPositionsInput[];
    shop: IShopsInput;
  };
}

export type IEmploymentsCreate = IEmploymentsDetail;
export type IEmploymentsEdit = IEmploymentsDetail;

export interface IEmploymentsList extends IList<IEmploymentsInput> {
  includes: {
    employees: IEmployeesInput[];
    employmentPositions: IEmploymentPositionsInput[];
    shops: IShopsInput[];
  };
}

export const employmentsCreate = (employeeId: identifierOrString, data: IEmploymentsOutput) =>
  base.post<IEmploymentsCreate>(`employees/${sanitizeId(employeeId)}/employments/`, { json: data });
export const employmentsDestroy = (id: identifierOrString) =>
  base.deleteEmpty(`employments/${sanitizeId(id)}/`);
export const employmentsDetail = (id: identifierOrString) =>
  base.get<IEmploymentsDetail>(`employments/${sanitizeId(id)}/`);
export const employmentsEdit = (id: identifierOrString, data: IEmploymentsOutput) =>
  base.patch<IEmploymentsEdit>(`employments/${sanitizeId(id)}/`, { json: data });
export const employmentsList = (shopId: identifierOrString) =>
  base.list<IEmploymentsList>("employments/", 1, 1000, { searchParams: { shopId } });

export interface IEmploymentPositionsDetail extends IDetail<IEmploymentPositionsInput> {
  includes: {
    employment: IEmploymentsInput;
    shops: IShopsInput[];
  };
}

export type IEmploymentPositionsCreate = IEmploymentPositionsDetail;
export type IEmploymentPositionsEdit = IEmploymentPositionsDetail;

export interface IEmploymentPositionsImport {
  rowsInvalid: {
    errorSaving: any[];
    invalidPayType: any[];
    invalidRole: any[];
    missingEmployeeId: any[];
    missingPosId: any[];
  };
  data: IEmploymentPositionsInput[];
}

export const employmentPositionsCreate = (data: IEmploymentPositionsOutput) =>
  base.post<IEmploymentPositionsCreate>("employment_positions/", { json: data });
export const employmentPositionsDestroy = (id: identifierOrString) =>
  base.deleteEmpty(`employment_positions/${id}/`);
export const employmentPositionsDetail = (id: identifierOrString) =>
  base.get<IEmploymentPositionsDetail>(`employment_positions/${id}/`);
export const employmentPositionsEdit = (id: identifierOrString, data: IEmploymentPositionsOutput) =>
  base.patch<IEmploymentPositionsEdit>(`employment_positions/${id}/`, {
    json: data,
  });

export const employmentPositionsImportCsv = (file: any) =>
  base.post<IEmploymentPositionsImport>("employment_positions/import/", {
    body: file,
    headers: { "Content-Type": "text/csv" },
  });
