import { onSnapshot } from "mobx-state-tree";
import React from "react";

import { storage, storageKeySnapshot } from "services";

import AuthStore from "./authStore";
import EmployeeStore from "./employeeStore";
import LaborsStore from "./laborsStore";
import RootStore, { IRootStore } from "./rootStore";
import SalesStore from "./salesStore";
import SchedulerStore from "./schedulerStore";
import ShopsStore from "./shopsStore";

const StoreContext = React.createContext<IRootStore>({} as IRootStore);

export const useStore = () => React.useContext(StoreContext);
export const { Provider } = StoreContext;

export const createStore = (): IRootStore => {
  const auth = AuthStore.create();
  const employees = EmployeeStore.create();
  const labors = LaborsStore.create();
  const sales = SalesStore.create();
  const scheduler = SchedulerStore.create();
  const shops = ShopsStore.create();

  const store = RootStore.create({ auth, employees, labors, sales, scheduler, shops } as any);

  onSnapshot(store, (snapshot) => storage.setItem(storageKeySnapshot, snapshot));

  return store;
};

export * from "./authStore";
export * from "./employeeStore";
export * from "./laborsStore";
export * from "./rootStore";
export * from "./salesStore";
export * from "./schedulerStore";
export * from "./shopsStore";

export * from "./models";
export * from "./types";
