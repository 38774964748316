interface IEnvironments {
  [environment: string]: {
    isProduction: boolean;
    name: string;
    sentryDsn: string;
  };
}

const environments: IEnvironments = {
  development: {
    isProduction: false,
    name: "Local",
    sentryDsn: "",
  },
  production: {
    isProduction: true,
    name: "Prod",
    sentryDsn: "https://1140bee1796d46fbb16722bd473c4632@o318150.ingest.sentry.io/1805089",
  },
};

const environmentCurrent = environments[process.env.NODE_ENV || "development"];

function fetchSetting(settingKey: string, isRequired = false) {
  const setting = process.env[settingKey];

  if (isRequired && !setting) {
    throw new Error(`Missing ${settingKey} in .env.local`);
  }

  return setting;
}

export default {
  apiBaseUrl: fetchSetting("REACT_APP_API_URL", true),
  ...environmentCurrent,
};
