import { Instance, types } from "mobx-state-tree";

import { Employee, EmploymentPositionRole } from "./employee";
import { Shop } from "./shop";

export type IRightsValue = "none" | "read" | "hours" | "write" | "full";

export interface IRights {
  dashboard: IRightsValue;
  employeeImport: IRightsValue;
  employeeNotes: IRightsValue;
  employeePositions: IRightsValue;
  employeeReports: IRightsValue;
  employees: IRightsValue;
  housekeeping: IRightsValue;
  labors: IRightsValue;
  payroll: IRightsValue;
  reports: IRightsValue;
  sales: IRightsValue;
  shopHours: IRightsValue;
  shopTaxes: IRightsValue;
  shops: IRightsValue;
  workSchedules: IRightsValue;
  workTargets: IRightsValue;
}

export const UserPermissions = types.model("UserPermissions", {
  rights: types.frozen<IRights>(),
  shops: types.array(types.reference(Shop)),
  topRole: types.frozen<EmploymentPositionRole>(),
  topRoleLevel: types.number,
});

export const User = types.model("User", {
  email: types.string,
  employee: types.maybeNull(types.reference(Employee)),
  id: types.identifierNumber,
  isRoot: types.boolean,
  permissions: UserPermissions,
});

export interface IUser extends Instance<typeof User> {}
