import { Instance, types } from "mobx-state-tree";

import { Date, DateTime, Money } from "../types";
import { EmploymentPosition } from "./employee";
import { Shop } from "./shop";

export const Invoice = types.model("Sale", {
  created: DateTime,
  employeeCode: types.string,
  id: types.identifierNumber,
  invoiceAt: DateTime,
  invoiceDate: Date,
  invoiceNumber: types.number,
  isVoided: types.boolean,
  modified: DateTime,
  netSales: Money,
  position: types.maybeNull(types.reference(EmploymentPosition)),
  shop: types.reference(Shop),
  tax: Money,
  tenderAmount: Money,
  tenderCount: types.number,
});

export interface IInvoice extends Instance<typeof Invoice> {}
