import { EmploymentPositionRole, IRightsValue } from "stores/models";

export function getRoleLevel(role: EmploymentPositionRole) {
  const roleLevels = {
    [EmploymentPositionRole.Associate]: 1,
    [EmploymentPositionRole.ShiftLeader]: 2,
    [EmploymentPositionRole.AssistantManager]: 3,
    [EmploymentPositionRole.Manager]: 4,
    [EmploymentPositionRole.AreaLeader]: 5,
    [EmploymentPositionRole.OpsDirector]: 6,
    [EmploymentPositionRole.Controller]: 7,
    [EmploymentPositionRole.Corporate]: 8,
  };

  return roleLevels[role] || 0;
}

export function canDelete(right: IRightsValue) {
  return ["full"].includes(right);
}

export function canPayroll(right: IRightsValue) {
  return ["hours", "full"].includes(right);
}

export function canRead(right: IRightsValue) {
  return ["read", "write", "full"].includes(right);
}

export function canWrite(right: IRightsValue) {
  return ["write", "full"].includes(right);
}
