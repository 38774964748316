import { Button, Icon, Input } from "antd";
import "antd/lib/date-picker/style";
import moment, { Moment } from "moment";
import RcCalendar from "rc-calendar";
import RcDatePicker from "rc-calendar/lib/Picker";
import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";

const Container = styled.span`
  position: relative;
`;

const Calendar = styled(RcCalendar)`
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  left: 0;
  position: absolute;
  top: 0;
  width: 286px;
  z-index: 1050;

  && .ant-calendar-body tr.ant-calendar-active-week,
  &&& .ant-calendar-today .ant-calendar-date {
    background: none;
    border-color: transparent;
    color: inherit;
    font-weight: normal;
  }
`;

function getWeekStartingDate(value: Moment | string | undefined | null) {
  if (moment.isMoment(value)) {
    const startingDate = value.clone();
    const weekday = startingDate.weekday();

    // Always set to starting day of the week so all the days of the week
    // are visible when selecting weeks that span two months.
    if (weekday <= 4) {
      startingDate.subtract(weekday + 2, "days");
    } else {
      startingDate.subtract(weekday - 5, "days");
    }

    return startingDate;
  } else if (value) {
    return moment(value, weekPickerFormat).add(4, "days");
  } else {
    return undefined;
  }
}

interface IProps {
  allowClear?: boolean;
  className?: string;
  defaultValue?: Moment | string;
  disabled?: boolean;
  footerRenderer?: (() => React.ReactNode) | null;
  onChange?: (week: string | null) => void;
  placeholder?: string;
  value?: Moment | string | null;
}

export const weekPickerFormat = "YYYY-[W]WW";

export const WeekPicker: React.FC<IProps> = ({
  allowClear = true,
  className,
  defaultValue,
  disabled,
  footerRenderer,
  onChange = () => {},
  placeholder = "Select week",
  value,
}) => {
  const element = useRef<RcDatePicker>(null);

  const [internalValue, setInternalValue] = useState<Moment | undefined>(() => {
    if (value !== undefined) return undefined;

    return getWeekStartingDate(defaultValue) || moment();
  });

  const week = useMemo(() => {
    if (!internalValue) return null;

    const inputValue = internalValue.clone();
    if (inputValue) {
      const weekday = inputValue.weekday();

      if (weekday <= 4) {
        inputValue.subtract(6, "days");
      } else if (weekday === 6) {
        const friday = inputValue.clone().subtract(1, "day");

        if (friday.year() !== inputValue.year()) {
          inputValue.subtract(1, "day");
        }
      }
    }

    return inputValue.format(weekPickerFormat);
  }, [internalValue]);

  useEffect(() => {
    onChange(week);
  }, [onChange, week]);

  useEffect(() => {
    if (value === undefined) return;

    setInternalValue(getWeekStartingDate(value));
  }, [value]);

  const handleCalendarChange = (date: Moment | null) => {
    if (date) setInternalValue(date);
  };

  const handlePickerChange = useCallback((changeValue: Moment | undefined) => {
    setInternalValue(getWeekStartingDate(changeValue));
  }, []);

  const handleFooterClick = useCallback(() => {
    handlePickerChange(moment());

    if (element.current) {
      element.current.close();
    }
  }, [handlePickerChange]);

  const dateRenderer = (currentDate: Moment, calendarValue: Moment) => {
    const currentWeek = currentDate.week();
    const currentWeekday = currentDate.weekday();
    const currentWeeksInYear = currentDate.weeksInYear();
    const valueWeek = calendarValue.week();
    const valueWeekday = calendarValue.weekday();
    const valueWeeksInYear = calendarValue.weeksInYear();

    const sunToThurs =
      valueWeekday <= 4 &&
      ((currentWeek === valueWeek && currentWeekday <= 4) ||
        ((currentWeek === valueWeek - 1 ||
          (currentWeek === currentWeeksInYear && valueWeek === 1)) &&
          currentWeekday > 4));
    const friToSat =
      valueWeekday > 4 &&
      ((currentWeek === valueWeek && currentWeekday > 4) ||
        ((currentWeek === valueWeek + 1 || (valueWeek === valueWeeksInYear && currentWeek === 1)) &&
          currentWeekday <= 4));
    const style: CSSProperties = {};
    if (sunToThurs || friToSat) {
      style.fontWeight = "bold";
      style.backgroundColor = "#bae7ff";
    }

    return (
      <div className="ant-calendar-date" style={style}>
        {currentDate.date()}
      </div>
    );
  };

  const clearSelection = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    handlePickerChange(undefined);
  };

  const calendarFooter = useMemo(() => {
    if (footerRenderer === null) {
      return () => null;
    } else if (footerRenderer) {
      return footerRenderer;
    } else {
      return () => {
        return (
          <span className="ant-calendar-footer-btn">
            <Button className="ant-calendar-today-btn" onClick={handleFooterClick} type="link">
              This Week
            </Button>
          </span>
        );
      };
    }
  }, [footerRenderer, handleFooterClick]);

  const calendar = (
    <Calendar
      dateRender={dateRenderer}
      onChange={handleCalendarChange}
      prefixCls="ant-calendar"
      renderFooter={calendarFooter}
      showDateInput={false}
      showToday={false}
      value={internalValue}
    />
  );

  const clearIcon =
    !disabled && allowClear && internalValue ? (
      <Icon
        type="close-circle"
        className="ant-calendar-picker-clear"
        onClick={clearSelection}
        theme="filled"
      />
    ) : null;

  const input = () => {
    return (
      <span style={{ display: "inline-block", width: "100%" }}>
        <Input
          className="ant-calendar-picker-input"
          disabled={disabled}
          placeholder={placeholder}
          readOnly
          suffix={<Icon style={{ color: "rgba(0, 0, 0, 0.25)" }} type="calendar" />}
          value={week || ""}
        />
        {clearIcon}
      </span>
    );
  };

  return (
    <Container className={`ant-calendar-picker ${className}`}>
      <RcDatePicker
        calendar={calendar}
        onChange={handlePickerChange}
        prefixCls="ant-calendar-picker-container"
        ref={element}
      >
        {input}
      </RcDatePicker>
    </Container>
  );
};
