export function sanitizeId(object: any) {
  return parseInt(object, 10);
}

export function sanitizeIdString(object: any) {
  return sanitizeId(object).toString();
}

export function getSearchParams(object: any) {
  const searchParams = new URLSearchParams();

  for (const key of Object.keys(object)) {
    const value = object[key];
    if (value == null) {
      // eslint-disable-next-line no-continue
      continue;
    }

    searchParams.set(key, value.toString());
  }

  return searchParams;
}
