import { IKeyValueMap } from "mobx";
import { applySnapshot, getSnapshot, IAnyModelType, Instance } from "mobx-state-tree";

export function mergeToMap<T>(
  data: any[],
  model: IAnyModelType,
  map: IKeyValueMap,
  key = "id"
): Array<Instance<T>> {
  return data.map((item) => {
    const entryNew = model.create(item);

    const entryOld = map.get(entryNew[key].toString());

    if (entryOld) {
      applySnapshot(entryOld, getSnapshot(entryNew));
      return entryOld;
    } else {
      map.set(entryNew[key], entryNew);
      return entryNew;
    }
  });
}
