import base from "./base";

export interface IHousekeepingSyncUnlinked {
  daysCount: number;
  daysCurrent: number;
  endedAt: timestamp;
  id: number;
  isOngoing: boolean;
  progress: number;
  startedAt: timestamp;
}

export interface IHousekeepingSyncUnlinkedStatus {
  data: IHousekeepingSyncUnlinked;
}

export type IHousekeepingSyncUnlinkedStop = IHousekeepingSyncUnlinkedStatus;

export const syncUnlinkedStatus = () =>
  base.get<IHousekeepingSyncUnlinkedStatus>("housekeeping/sync_unlinked/");
export const syncUnlinkedStart = () => base.postEmpty("housekeeping/sync_unlinked/");
export const syncUnlinkedStop = () =>
  base.delete<IHousekeepingSyncUnlinkedStop>("housekeeping/sync_unlinked/");
