import dayjs, { Dayjs } from "dayjs";
import { types } from "mobx-state-tree";

import { apiDateFormat, apiDateTimeFormat } from "utils/constants";

export const Date = types.custom<string | null, Dayjs | null>({
  name: "Date",
  fromSnapshot(snapshot) {
    if (!snapshot) return null;

    return dayjs(`${snapshot}T00:00:00+0000`, apiDateTimeFormat);
  },
  toSnapshot(value) {
    if (!value) return null;

    return value.utc().format(apiDateFormat);
  },
  isTargetType(value: any) {
    return dayjs.isDayjs(value);
  },
  getValidationMessage(snapshot) {
    if (dayjs.isDayjs(snapshot)) {
      return "Invalid Date object";
    }

    return "";
  },
});

export type IDate = Dayjs | null;
