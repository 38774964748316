import { types } from "mobx-state-tree";

export const Phone = types.custom<string, string>({
  name: "Phone",
  fromSnapshot(snapshot) {
    return snapshot;
  },
  toSnapshot(value) {
    return value;
  },
  isTargetType(value: any) {
    return typeof value === "string";
  },
  getValidationMessage() {
    return "Invalid Phone object";
  },
});

export type IPhone = string;
