export const apiDateFormat = "YYYY-MM-DD";
export const apiDateTimeFormat = "YYYY-MM-DDTHH:mm:ssZZ";
export const fileDateTimeFormat = "YYYY-MM-DD-HHmmss";

export const blankCharacter = "—";

export const genders = ["Male", "Female"];

// tslint:disable:object-literal-sort-keys
export const states: { [key: string]: string } = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District of Columbia",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};
// tslint:enable:object-literal-sort-keys

export const timezones = [
  "US/Alaska",
  "US/Aleutian",
  "US/Arizona",
  "US/Central",
  "US/East-Indiana",
  "US/Eastern",
  "US/Hawaii",
  "US/Indiana-Starke",
  "US/Michigan",
  "US/Mountain",
  "US/Pacific",
  "US/Samoa",
];

export const defaultStateTimezones: { [key: string]: string } = {
  AK: "US/Alaska",

  AZ: "US/Arizona",

  AL: "US/Central",
  AR: "US/Central",
  IA: "US/Central",
  IL: "US/Central",
  KS: "US/Central",
  KY: "US/Central",
  LA: "US/Central",
  MN: "US/Central",
  MO: "US/Central",
  MS: "US/Central",
  ND: "US/Central",
  NE: "US/Central",
  OK: "US/Central",
  SD: "US/Central",
  TN: "US/Central",
  TX: "US/Central",
  WI: "US/Central",

  CT: "US/Eastern",
  DC: "US/Eastern",
  DE: "US/Eastern",
  FL: "US/Eastern",
  GA: "US/Eastern",
  IN: "US/Eastern",
  MA: "US/Eastern",
  MD: "US/Eastern",
  ME: "US/Eastern",
  MI: "US/Eastern",
  NC: "US/Eastern",
  NH: "US/Eastern",
  NJ: "US/Eastern",
  NY: "US/Eastern",
  OH: "US/Eastern",
  PA: "US/Eastern",
  RI: "US/Eastern",
  SC: "US/Eastern",
  VA: "US/Eastern",
  VT: "US/Eastern",
  WV: "US/Eastern",

  HI: "US/Hawaii",

  CO: "US/Mountain",
  ID: "US/Mountain",
  MT: "US/Mountain",
  NM: "US/Mountain",
  UT: "US/Mountain",
  WY: "US/Mountain",

  CA: "US/Pacific",
  NV: "US/Pacific",
  OR: "US/Pacific",
  WA: "US/Pacific",
};

export const weekdays: { [key: string]: string } = {
  "1": "Monday",
  "2": "Tuesday",
  "3": "Wednesday",
  "4": "Thursday",
  "5": "Friday",
  "6": "Saturday",
  "7": "Sunday",
};
