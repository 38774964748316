import dayjs, { Dayjs } from "dayjs";
import { types } from "mobx-state-tree";

import { apiDateTimeFormat } from "utils/constants";

export const DateTime = types.custom<string | null, Dayjs | null>({
  name: "DateTime",
  fromSnapshot(snapshot) {
    if (!snapshot) return null;

    return dayjs(snapshot, apiDateTimeFormat);
  },
  toSnapshot(value) {
    if (!value) return null;

    return value.utc().format(apiDateTimeFormat);
  },
  isTargetType(value: any) {
    return dayjs.isDayjs(value);
  },
  getValidationMessage(snapshot) {
    if (dayjs.isDayjs(snapshot)) {
      return "Invalid DateTime object";
    }

    return "";
  },
});

export type IDateTime = Dayjs | null;
