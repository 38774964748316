import { Instance, SnapshotOut, types } from "mobx-state-tree";

import { Date, DateTime, Decimal, Money } from "../types";
import { EmploymentPosition } from "./employee";
import { Shop } from "./shop";

export const Labor = types.model("Labor", {
  checkDate: Date,
  checkIn: types.maybeNull(types.string),
  checkOut: types.maybeNull(types.string),
  created: DateTime,
  employeeName: types.string,
  hours: Decimal,
  id: types.identifierNumber,
  isApproved: types.boolean,
  modified: DateTime,
  notes: types.string,
  posIdentifier: types.string,
  position: types.maybeNull(types.reference(EmploymentPosition)),
  shop: types.reference(Shop),
  stationName: types.string,
});

export const LaborHours = types.model("LaborHours", {
  cardTips: Money,
  id: types.identifierNumber,
  overtimeHours: Decimal,
  overtimePayRate: Money,
  position: types.reference(EmploymentPosition),
  regularHours: Decimal,
  regularPayRate: Money,
  totalHours: Decimal,
});

export interface ILabor extends Instance<typeof Labor> {}
export interface ILaborSnapshotOut extends SnapshotOut<typeof Labor> {}

export interface ILaborHours extends Instance<typeof LaborHours> {}
