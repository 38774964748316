import base from "./base";
import type { IEmployeesInput, IEmploymentPositionsInput, IEmploymentsInput } from "./employees";

export type IAuthLoginRightValue = "none" | "read" | "hours" | "write" | "full";

export interface IAuthLogin {
  data: {
    expiry: string | null;
    token: string;
    user: {
      id: identifier;
      email: string;
      employee: identifier | null;
      isRoot: boolean;
      permissions: {
        rights: {
          dashboard: IAuthLoginRightValue;
          employeeImport: IAuthLoginRightValue;
          employeeNotes: IAuthLoginRightValue;
          employeePositions: IAuthLoginRightValue;
          employeeReports: IAuthLoginRightValue;
          employees: IAuthLoginRightValue;
          housekeeping: IAuthLoginRightValue;
          labors: IAuthLoginRightValue;
          payroll: IAuthLoginRightValue;
          reports: IAuthLoginRightValue;
          sales: IAuthLoginRightValue;
          shopHours: IAuthLoginRightValue;
          shopTaxes: IAuthLoginRightValue;
          shops: IAuthLoginRightValue;
          workSchedules: IAuthLoginRightValue;
          workTargets: IAuthLoginRightValue;
        };
        shops: identifier[];
        topRole: string;
        topRoleLevel: number;
      };
    };
  };
  includes: {
    employees: IEmployeesInput[];
    employments: IEmploymentsInput[];
    employmentPositions: IEmploymentPositionsInput[];
  };
}

export type IAuthLogout = null;
export type IAuthLogoutAll = null;
export type IAuthReauth = IAuthLogin;

export const login = (email: string, password: string) =>
  base.post<IAuthLogin>("auth/login/", {
    json: { email, password },
    public: true,
  });
export const logout = () => base.postEmpty("auth/logout/");
export const logoutAll = () => base.postEmpty("auth/logoutall/");
export const reauth = () => base.post<IAuthReauth>("auth/reauth/");
