import { applySnapshot, flow, Instance, types } from "mobx-state-tree";

import api from "api";
import { storage, storageKeySnapshot } from "services";

import AuthStore from "./authStore";
import EmployeeStore from "./employeeStore";
import LaborsStore from "./laborsStore";
import SalesStore from "./salesStore";
import SchedulerStore from "./schedulerStore";
import ShopsStore from "./shopsStore";

const RootStore = types
  .model("RootStore", {
    // Properties
    version: types.optional(types.number, 1),

    // Stores
    auth: AuthStore,
    employees: EmployeeStore,
    labors: LaborsStore,
    sales: SalesStore,
    scheduler: SchedulerStore,
    shops: ShopsStore,
  })
  .volatile(() => ({
    initialized: false,
  }))
  .actions((self) => ({
    load: flow(function* () {
      const snapshot = yield storage.getItem(storageKeySnapshot);

      if (snapshot && snapshot.auth) {
        api.config.token = snapshot.auth.token;
      }

      try {
        applySnapshot(self, snapshot);
      } catch (error: any) {
        // Raises an error when the store has changed.
      }

      if (api.config.token) {
        yield self.auth.reauth();
      }

      self.initialized = true;
    }),

    reset() {
      applySnapshot(self, {
        auth: {},
        employees: {},
        labors: {},
        sales: {},
        scheduler: {},
        shops: {},
      });
    },
  }));

export interface IRootStore extends Instance<typeof RootStore> {}

export default RootStore;
