import { Location } from "history";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function getSearchParams(location: Location) {
  const searchParams = new URLSearchParams(location.search);

  return Object.fromEntries(searchParams as any);
}

export function useSearchParams<T>() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useState<T>();

  useEffect(() => {
    setSearchParams(getSearchParams(location) as any);
  }, [location]);

  return searchParams;
}
