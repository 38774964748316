import { Decimal as DecimalJs } from "decimal.js";
import { detach, Instance, types } from "mobx-state-tree";

import { IShopsDashboardActiveEmployees } from "api/shops";
import { weekdays } from "utils/constants";
import { slugify } from "utils/formatting";

import { Date, DateTime, Decimal, Money, Percentage, Phone } from "../types";

export const ShopSchedule = types
  .model("ShopSchedule", {
    closingHour: types.maybeNull(types.string),
    created: DateTime,
    id: types.identifierNumber,
    minimumLaborPerHour: Money,
    modified: DateTime,
    openingHour: types.maybeNull(types.string),
    shop: types.late((): any => types.reference(Shop)),
    weekday: types.number,
  })
  .actions((self) => ({
    destroy() {
      if (self.shop) self.shop.schedules.splice(self.shop.schedules.indexOf(self), 1);

      detach(self);
    },
  }))
  .views((self) => ({
    get weekdayName() {
      return weekdays[self.weekday] || "";
    },

    get index() {
      return `${self.shop.shopIdentifier}-${self.weekday}`;
    },
  }));

export const Shop = types
  .model("Shop", {
    address: types.string,
    ccProcessingCharges: Percentage,
    city: types.string,
    county: types.string,
    countyTax: Percentage,
    created: DateTime,
    ein: types.string,
    futaTax: Percentage,
    hasCcTips: types.boolean,
    id: types.identifierNumber,
    isActive: types.boolean,
    medicareTax: Percentage,
    modified: DateTime,
    name: types.string,
    nickname: types.string,
    openingDate: Date,
    paylocityIdentifier: types.string,
    phone: Phone,
    posIdentifier: types.maybeNull(types.string),
    salesTax: Percentage,
    salesTaxId: types.string,
    schedulerTax: Percentage,
    schedules: types.array(types.reference(ShopSchedule)),
    shopIdentifier: types.string,
    socialSecurityTax: Percentage,
    state: types.string,
    suiNumber: types.string,
    suiTax: Percentage,
    timezone: types.string,
  })
  .views((self) => ({
    get displayName() {
      return `${self.name} (${self.nickname})`;
    },

    get slug() {
      return slugify([self.id, self.name]);
    },

    get sortedSchedules() {
      return self.schedules.slice().sort((a, b) => a.weekday - b.weekday);
    },
  }));

export const ShopDashboard30m = types.model("ShopDashboard30m", {
  activeEmployees: types.array(types.frozen<IShopsDashboardActiveEmployees>()),
  activeEmployeesLevel: Decimal,
  isOpen: types.boolean,
  leadCount: types.number,
  productivity: Decimal,
  salesPerHour: Money,
  shop: types.reference(Shop),
  status: types.union(types.literal("red"), types.literal("green"), types.literal("yellow")),
});

export const ShopDashboardDaily = types
  .model("ShopDashboardDaily", {
    minimumLabor: Percentage,
    percentageLabor: Percentage,
    salesAmount: Money,
    salesPerHour: Money,
    shop: types.maybeNull(types.reference(Shop)),
    status: types.union(types.literal("red"), types.literal("green"), types.literal("yellow")),
    totalLabor: Money,
  })
  .views((self) => ({
    get minimumLaborAmount() {
      if (!self.salesAmount || !self.minimumLabor) {
        return new DecimalJs(0);
      }

      return self.salesAmount.mul(self.minimumLabor).mul(0.01);
    },
  }));

export const ShopDashboardWeekly = types.model("ShopDashboardWeekly", {
  minimumLabor: Percentage,
  percentageLabor: Percentage,
  salesAmount: Money,
  salesPerHour: Money,
  shop: types.reference(Shop),
  status: types.union(types.literal("red"), types.literal("green"), types.literal("yellow")),
  totalLabor: Money,
});

export interface IShop extends Instance<typeof Shop> {}

export interface IShopDashboard30m extends Instance<typeof ShopDashboard30m> {}

export interface IShopDashboardDaily extends Instance<typeof ShopDashboardDaily> {}

export interface IShopDashboardWeekly extends Instance<typeof ShopDashboardWeekly> {}

export interface IShopSchedule extends Instance<typeof ShopSchedule> {}
