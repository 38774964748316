import { Table } from "antd";
import React from "react";
import styled from "styled-components";

const StyledTable = styled(Table)`
  .ant-table {
    overflow: auto;
  }
`;

export interface ICsvTable {
  data: any[];
  title: string;
}

export const CsvTable: React.FC<ICsvTable> = ({ data, title }) => {
  if (data.length === 0) return null;

  const columns = Object.keys(data[0]).map((key) => ({ dataIndex: key, title: key }));
  const dataSource = data.map((row, index) => ({ ...row, key: index }));

  return (
    <>
      <h3>{title}</h3>

      <StyledTable
        columns={columns}
        dataSource={dataSource}
        pagination={{ defaultPageSize: 25 }}
        size="middle"
      />
    </>
  );
};
