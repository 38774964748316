import {
  IWorkSchedulesDates,
  IWorkSchedulesSales,
  IWorkSchedulesTotalHours,
  IWorkSchedulesTotalLaborCost,
  IWorkTargetSalesForecast,
  IWorkTargetSalesForecastKeys,
} from "api/scheduler";
import { Decimal as DecimalJs } from "decimal.js";
import { getParent, getRoot, Instance, types } from "mobx-state-tree";
import { sum } from "ramda";
import { IEmploymentPosition, PayType } from "stores/models/employee";
import { IRootStore } from "stores/rootStore";
import { ISchedulerStore } from "stores/schedulerStore";
import { Date, DateTime, Decimal2 } from "stores/types";
import { sortWithKey } from "utils/ramda";

import { Shop } from "./shop";

const latestHourAfterMidnight = 4;

function getNextTimeSlot(timeSlot: string) {
  const timeSlotSplit = timeSlot.split(":");
  const timeSlotHours = Number(timeSlotSplit[0]);
  const timeSlotMinutes = Number(timeSlotSplit[1]);

  const nextSlotValue = timeSlotHours * 60 + timeSlotMinutes + 30;
  const nextSlotHours = (Math.floor(nextSlotValue / 60) % 24).toString().padStart(2, "0");
  const nextSlotMinutes = (nextSlotValue % 60).toString().padStart(2, "0");

  return `${nextSlotHours}:${nextSlotMinutes}`;
}

export const WorkScheduleSlots = types.array(types.string);

export interface IWorkScheduleSlots extends Instance<typeof WorkScheduleSlots> {}

export const WorkScheduleDatesItem = types
  .model("WorkScheduleDatesItem", {
    closingHour: types.string,
    date: Date,
    openingHour: types.string,
    slots: types.map(WorkScheduleSlots),
    weekday: types.identifierNumber,
  })
  .actions((self) => ({
    toggleSlot(positionId: identifierOrString, hour: string, isActive: boolean) {
      let hours: string[] = self.slots.get(positionId.toString()) || [];

      if (isActive) {
        hours.push(hour);
      } else {
        hours = hours.filter((value) => value !== hour);
      }

      if (hours.length === 0) {
        self.slots.delete(positionId.toString());
      } else {
        self.slots.set(positionId.toString(), hours);
      }
    },
  }))
  .views((self) => ({
    get availableSlots(): string[] {
      const map: IWorkScheduleDatesMap = getParent(self);
      const dates: IWorkSchedulesDates = getParent(map);
      const schedule: IWorkSchedule = getParent(dates);

      return Array.from(schedule.availableSlots);
    },
  }))
  .views((self) => ({
    getTimeSlots: (positionId: identifierOrString): string[] => {
      return self.slots.get(positionId.toString()) || [];
    },

    getTimeRanges: (positionId: identifierOrString): string[] => {
      const timeSlots: string[] = self.slots.get(positionId.toString()) || [];
      const sortedTimeSlots: string[] = [];

      let startSlot: string | null = null;
      let endSlot: string | null = null;
      self.availableSlots.forEach((currentSlot) => {
        const isIncluded = timeSlots.includes(currentSlot);

        if (!startSlot) {
          if (isIncluded) {
            startSlot = currentSlot;
            endSlot = currentSlot;
          }
          return;
        }

        const currentSlotSplit = currentSlot.split(":");
        let currentSlotHours = Number(currentSlotSplit[0]);
        const currentSlotMinutes = Number(currentSlotSplit[1]);

        if (currentSlotHours <= latestHourAfterMidnight) {
          currentSlotHours += 24;
        }

        const startSlotSplit = currentSlot.split(":");
        let startSlotHours = Number(startSlotSplit[0]);
        const startSlotMinutes = Number(startSlotSplit[1]);

        if (startSlotHours <= latestHourAfterMidnight) {
          startSlotHours += 24;
        }

        const currentSlotValue = currentSlotHours * 60 + currentSlotMinutes;
        const startSlotValue = startSlotHours * 60 + startSlotMinutes;

        const difference = currentSlotValue - startSlotValue;

        if (isIncluded) {
          if (difference <= 30) {
            endSlot = currentSlot;
          } else {
            sortedTimeSlots.push(`${startSlot} – ${getNextTimeSlot(endSlot!)}`);

            startSlot = currentSlot;
            endSlot = currentSlot;
          }
        } else {
          sortedTimeSlots.push(`${startSlot} – ${getNextTimeSlot(endSlot!)}`);

          startSlot = null;
          endSlot = null;
        }
      });

      if (startSlot) {
        sortedTimeSlots.push(`${startSlot} – ${getNextTimeSlot(endSlot!)}`);
      }

      return sortedTimeSlots;
    },
  }))
  .views((self) => ({
    get totalHours() {
      const slots = Array.from<IWorkScheduleSlots>(self.slots.values());

      return slots.reduce(
        (previousValue, currentValue) => previousValue + currentValue.length * 0.5,
        0
      );
    },

    getHoursFromPosition: (positionId: identifierOrString) => {
      return self.getTimeSlots(positionId).length * 0.5;
    },

    getHoursFromTimeSlot: (timeSlot: string) => {
      const slots = Array.from<IWorkScheduleSlots>(self.slots.values());
      const hours = slots.reduce<string[]>(
        (previousValue, currentValue) => [...previousValue, ...Array.from(currentValue)],
        []
      );

      return hours.filter((hour) => hour === timeSlot).length * 0.5;
    },

    hasHours: (positionId: identifierOrString, time: string) => {
      return self.getTimeSlots(positionId).includes(time);
    },
  }));

export interface IWorkScheduleDatesItem extends Instance<typeof WorkScheduleDatesItem> {}

export const WorkScheduleDatesMap = types.map(WorkScheduleDatesItem);

export interface IWorkScheduleDatesMap extends Instance<typeof WorkScheduleDatesMap> {}

export const WorkScheduleDates = types.model("WorkScheduleDates", {
  approved: WorkScheduleDatesMap,
  current: WorkScheduleDatesMap,
});

export interface IWorkScheduleDatesItems extends Instance<typeof WorkScheduleDates> {}

export const WorkSchedule = types
  .model("WorkSchedule", {
    availableSlots: types.array(types.string),
    dates: WorkScheduleDates,
    id: types.identifier,
    isApproved: types.boolean,
    isSaved: types.boolean,
    otherHours: types.frozen<IWorkSchedulesTotalHours>(),
    otherLaborCost: types.frozen<IWorkSchedulesTotalLaborCost>(),
    sales: types.frozen<IWorkSchedulesSales>(),
    shop: types.reference(Shop),
    week: types.string,
  })
  .volatile(() => ({
    mode: "current",
  }))
  .actions((self) => ({
    toggleMode: (mode?: string) => {
      if (mode) {
        self.mode = mode;
      } else {
        self.mode = self.mode === "current" ? "approved" : "current";
      }
    },
  }))
  .views((self) => ({
    get currentHours() {
      const dates = Array.from<IWorkScheduleDatesItem>(self.dates.current.values());

      const employeeStore = getRoot<IRootStore>(self).employees;
      const currentHours: IWorkSchedulesTotalHours = {};
      dates.forEach((date) => {
        date.slots.forEach((hours, positionId) => {
          const position = employeeStore.getPosition(positionId);
          if (!position) return;

          const prevHours = currentHours[position.employment.employee.id] || 0;
          currentHours[position.employment.employee.id] = prevHours + hours.length / 2.0;
        });
      });

      return currentHours;
    },

    get currentLaborCost() {
      const dates = Array.from<IWorkScheduleDatesItem>(self.dates.current.values());

      const employeeStore = getRoot<IRootStore>(self).employees;
      const currentLaborCost: IWorkSchedulesTotalLaborCost = {};
      dates.forEach((date) => {
        date.slots.forEach((hours, positionId) => {
          const position = employeeStore.getPosition(positionId);
          if (!position) return;

          const currentHours = hours.length / 2.0;
          const prevLaborCost = currentLaborCost[position.employment.employee.id] || 0;

          if (currentHours === 0 || !position.payRate) return;

          if (position.payType === PayType.Salary) {
            currentLaborCost[position.employment.employee.id] =
              prevLaborCost + position.payRate.toNumber() / 52;
          } else {
            currentLaborCost[position.employment.employee.id] =
              prevLaborCost + currentHours * position.payRate.toNumber();
          }
        });
      });

      return currentLaborCost;
    },
  }))
  .views((self) => ({
    getCurrentShopLaborCost: (position: IEmploymentPosition) => {
      return self.currentLaborCost[position.employment.employee.id] || 0;
    },

    getOtherShopLaborCost: (position: IEmploymentPosition) => {
      return self.otherLaborCost[position.employment.employee.id] || 0;
    },

    getCurrentShopHours: (position: IEmploymentPosition) => {
      return self.currentHours[position.employment.employee.id] || 0;
    },

    getOtherShopHours: (position: IEmploymentPosition) => {
      return self.otherHours[position.employment.employee.id] || 0;
    },
  }))
  .views((self) => ({
    getDates: () => {
      if (self.mode === "current") {
        return self.dates.current;
      }

      return self.dates.approved;
    },

    getOverallShopHours: (position: IEmploymentPosition) => {
      return self.getCurrentShopHours(position) + self.getOtherShopHours(position);
    },

    getSales: (weekday: number, time: time) => {
      const hours = Number(time.split(":")[0]);
      let weekdayActual = hours <= latestHourAfterMidnight ? weekday + 1 : weekday;
      if (weekdayActual > 7) {
        weekdayActual = 1;
      }

      const scheduleStore: ISchedulerStore = getParent(getParent(self));
      const target = scheduleStore.targets.get(`${self.shop.id}-${self.week}`);
      let salesForecast = 0;
      if (target) {
        salesForecast =
          target.salesForecast[weekdayActual.toString() as IWorkTargetSalesForecastKeys] || 0;
      }

      return new DecimalJs(self.sales[`${weekdayActual}-${time}`] || 0).mul(salesForecast);
    },
  }))
  .views((self) => ({
    getProductivity: (weekday: number, time: time) => {
      const sales = self.getSales(weekday, time);
      const date = self.getDates().get(weekday.toString());

      if (!date) return new DecimalJs(0);

      const hours = date.getHoursFromTimeSlot(time) * 2;

      if (!hours) return new DecimalJs(0);

      return sales.dividedBy(hours);
    },

    getTotalHoursFromPosition: (positionId: identifierOrString) => {
      const dates = Array.from<IWorkScheduleDatesItem>(self.getDates().values());

      return dates.reduce(
        (previousValue, currentValue) =>
          previousValue + currentValue.getHoursFromPosition(positionId),
        0
      );
    },

    isOvertime: (position: IEmploymentPosition) => {
      return position.hasOvertime && self.getOverallShopHours(position) > 40;
    },

    get totalHours() {
      const dates = Array.from<IWorkScheduleDatesItem>(self.getDates().values());

      return dates.reduce(
        (previousValue, currentValue) => previousValue + currentValue.totalHours,
        0
      );
    },

    get sortedDates() {
      const dates = Array.from<IWorkScheduleDatesItem>(self.getDates().values());

      return sortWithKey<IWorkScheduleDatesItem>(dates, "date");
    },
  }))
  .views((self) => ({
    getLaborCost: (position: IEmploymentPosition) => {
      const hours = self.getTotalHoursFromPosition(position.id);

      if (!position.payRate || hours === 0) {
        return new DecimalJs(0);
      }

      if (position.payType === PayType.Salary) {
        return position.payRate.div(52);
      }

      if (hours <= 40) {
        return position.payRate.mul(hours);
      }

      const regularHours = 40;
      const overtimeHours = hours - 40;
      const overtimePayRate =
        (self.getCurrentShopLaborCost(position) + self.getOtherShopLaborCost(position)) /
        (self.getCurrentShopHours(position) + self.getOtherShopHours(position));

      return position.payRate.mul(regularHours).add(overtimeHours * overtimePayRate);
    },
  }));

export interface IWorkSchedule extends Instance<typeof WorkSchedule> {}

export const WorkTarget = types
  .model("WorkTarget", {
    approvedHours: types.maybeNull(types.number),
    created: DateTime,
    id: types.identifier,
    laborTarget: Decimal2,
    modified: DateTime,
    salesForecast: types.frozen<IWorkTargetSalesForecast>(),
    shop: types.reference(Shop),
    week: types.string,
  })
  .views((self) => ({
    get salesForecastTotal() {
      return sum(Object.values(self.salesForecast));
    },
  }));

export interface IWorkTarget extends Instance<typeof WorkTarget> {}
