import { useEffect, useRef } from "react";

export type UseIntervalCallback = () => void;

export function useInterval(callback: UseIntervalCallback, delay: number | null) {
  const savedCallback = useRef<UseIntervalCallback>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) savedCallback.current();
    }

    if (delay !== null) {
      const intervalId = setInterval(tick, delay);
      return () => clearInterval(intervalId);
    }

    return () => {};
  }, [delay]);
}
