import { flow, getRoot, Instance, types } from "mobx-state-tree";

import api from "api";
import {
  IWorkSchedulesApprove,
  IWorkSchedulesList,
  IWorkSchedulesOutput,
  IWorkSchedulesUnapprove,
  IWorkTargetOutput,
  IWorkTargetsSet,
} from "api/scheduler";
import { IShopsStore } from "stores/shopsStore";
import { mergeToMap } from "utils/stores";

import type { IEmployeeStore } from "./employeeStore";
import type { IRootStore } from "./rootStore";
import { IWorkSchedule, IWorkTarget, WorkSchedule, WorkTarget } from "./models";

const SchedulerStore = types
  .model("SchedulerStore", {
    schedules: types.map(WorkSchedule),
    targets: types.map(WorkTarget),
  })
  .actions((self) => {
    const mergeSchedules = (data: any[]) => {
      return mergeToMap<IWorkSchedule>(data, WorkSchedule, self.schedules);
    };

    const mergeTargets = (data: any[]) => {
      return mergeToMap<IWorkTarget>(data, WorkTarget, self.targets);
    };

    return {
      mergeSchedules,

      schedulesList: flow(function* (shopId: identifierOrString, week: string) {
        const response: IWorkSchedulesList = yield api.scheduler.workSchedulesList(shopId, week);

        const employeeStore: IEmployeeStore = getRoot<IRootStore>(self).employees;

        employeeStore.merge(response.includes.employees);
        employeeStore.mergeEmployments(response.includes.employments);
        const positions = employeeStore.mergeEmploymentPositions(
          response.includes.employmentPositions
        );

        const [schedule] = mergeSchedules([response.data]);
        const [target] = mergeTargets([response.includes.target]);

        return { positions, schedule, target };
      }),

      schedulesSet: flow(function* (output: IWorkSchedulesOutput) {
        const response: IWorkSchedulesList = yield api.scheduler.workSchedulesSet(output);

        const employeeStore: IEmployeeStore = getRoot<IRootStore>(self).employees;

        employeeStore.merge(response.includes.employees);
        employeeStore.mergeEmployments(response.includes.employments);
        const positions = employeeStore.mergeEmploymentPositions(
          response.includes.employmentPositions
        );

        const [schedule] = mergeSchedules([response.data]);
        const [target] = mergeTargets([response.includes.target]);

        return { positions, schedule, target };
      }),

      schedulesApprove: flow(function* (id: string) {
        const response: IWorkSchedulesApprove = yield api.scheduler.workSchedulesApprove(id);

        const employeeStore: IEmployeeStore = getRoot<IRootStore>(self).employees;

        employeeStore.merge(response.includes.employees);
        employeeStore.mergeEmployments(response.includes.employments);
        const positions = employeeStore.mergeEmploymentPositions(
          response.includes.employmentPositions
        );

        const [schedule] = mergeSchedules([response.data]);
        const [target] = mergeTargets([response.includes.target]);

        return { positions, schedule, target };
      }),

      schedulesUnapprove: flow(function* (id: string) {
        const response: IWorkSchedulesUnapprove = yield api.scheduler.workSchedulesUnapprove(id);

        const employeeStore: IEmployeeStore = getRoot<IRootStore>(self).employees;

        employeeStore.merge(response.includes.employees);
        employeeStore.mergeEmployments(response.includes.employments);
        const positions = employeeStore.mergeEmploymentPositions(
          response.includes.employmentPositions
        );

        const [schedule] = mergeSchedules([response.data]);
        const [target] = mergeTargets([response.includes.target]);
        schedule.toggleMode("current");

        return { positions, schedule, target };
      }),

      targetsSet: flow(function* (output: IWorkTargetOutput) {
        const response: IWorkTargetsSet = yield api.scheduler.workTargetsSet(output);

        const shopsStore: IShopsStore = getRoot<IRootStore>(self).shops;

        shopsStore.merge([response.includes.shop]);

        const [target] = mergeTargets([response.data]);
        return target;
      }),
    };
  });

export interface ISchedulerStore extends Instance<typeof SchedulerStore> {}

export default SchedulerStore;
