import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinnerThird as fadSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import {
  faAt as fasAt,
  faCheck as fasCheck,
  faEdit as fasEdit,
  faLock as fasLock,
  faSync as fasSync,
  faTrashAlt as fasTrashAlt,
  faUndo as fasUndo,
  faUnlock as fasUnlock,
  faUpload as fasUpload,
  faUserTie as fasUserTie,
} from "@fortawesome/pro-solid-svg-icons";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { message } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import { configure as configureMobx } from "mobx";
import makeInspectable from "mobx-devtools-mst";
import React from "react";
import ReactDOM from "react-dom";

// Import global stylesheet
import "./index.css";

import { createStore } from "stores";

import config from "./config";
import Application from "./scenes/Application";
import * as serviceWorker from "./serviceWorker";

// Ant Design configuration
message.config({ duration: 5, maxCount: 3 });

// Dayjs plugins
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(utc);

// Font-awesome configuration
library.add(
  fadSpinnerThird,
  fasAt,
  fasCheck,
  fasEdit,
  fasLock,
  fasSync,
  fasTrashAlt,
  fasUndo,
  fasUnlock,
  fasUpload,
  fasUserTie
);

// Mobx configuration
configureMobx({ enforceActions: "observed" });
const store = createStore();

// Sentry configuration
Sentry.init({
  dsn: config.sentryDsn,
  environment: config.name,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

function start() {
  ReactDOM.render(<Application store={store} />, document.getElementById("root"));

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}

if (config.isProduction) {
  start();
} else {
  makeInspectable(store);

  // HACK: This fixes the issue of mobx-dev-tools not detecting initial state.
  setTimeout(start, 1000);
}
