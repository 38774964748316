import { Button, Layout, Menu } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { useStore } from "stores";
import { canPayroll, canRead } from "utils/permissions";

const Container = styled(Layout.Header)`
  align-items: center;
  background-color: #ffffff;
  box-shadow: rgb(240, 241, 242) 0 2px 8px 0;
  display: flex;
`;

const Divider = styled.div`
  border-left: 1px solid #ebedf0;
  height: 20px;
  margin: 0 10px 0 30px;
`;

const LogoutButton = styled(Button)`
  margin-left: 20px;
`;

const StyledMenu = styled(Menu)`
  && {
    border-bottom: none;
    flex: 1;
  }
`;

const Title = styled.h1`
  margin: 0;
`;

const Header: React.FC = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const store = useStore();
  const user = store.auth.user!;

  const currentKey = location.pathname.split("/")[1];

  const handleLogOut = async () => {
    await store.auth.logout();
    history.push("/");
  };

  return (
    <Container>
      <Link to="/">
        <Title>Athena</Title>
      </Link>

      <Divider />

      <StyledMenu mode="horizontal" selectedKeys={[currentKey]}>
        {canRead(user.permissions.rights.employees) && (
          <Menu.Item key="employees">
            <Link to="/employees">Employees</Link>
          </Menu.Item>
        )}

        {canRead(user.permissions.rights.labors) && (
          <Menu.Item key="labors">
            <Link to="/labors">Labors</Link>
          </Menu.Item>
        )}

        {canPayroll(user.permissions.rights.payroll) && (
          <Menu.Item key="payrolls">
            <Link to="/payrolls/new">Payrolls</Link>
          </Menu.Item>
        )}

        {canRead(user.permissions.rights.dashboard) && (
          <Menu.Item key="reports">
            <Link to="/reports">Reports</Link>
          </Menu.Item>
        )}

        {canRead(user.permissions.rights.sales) && (
          <Menu.Item key="sales">
            <Link to="/sales">Sales</Link>
          </Menu.Item>
        )}

        {canRead(user.permissions.rights.workSchedules) && (
          <Menu.Item key="schedules">
            <Link to="/schedules">Schedules</Link>
          </Menu.Item>
        )}

        {canRead(user.permissions.rights.shops) && (
          <Menu.Item key="shops">
            <Link to="/shops">Shops</Link>
          </Menu.Item>
        )}
      </StyledMenu>

      <LogoutButton onClick={handleLogOut}>Log Out</LogoutButton>
    </Container>
  );
});

export default Header;
