import { Alert } from "antd";
import React from "react";
import styled from "styled-components";

import { IValidationErrors } from "api";

const StyledAlert = styled(Alert)`
  margin-bottom: 15px;
`;

const Description = styled.ul`
  margin: 0;
  padding: 0 15px;
`;

export interface IFormErrors {
  errors: IValidationErrors;
}

export const FormErrors: React.FC<IFormErrors> = ({ errors }) => {
  if (!errors.nonFieldErrors) {
    return null;
  }

  const description = (
    <Description>
      {errors.nonFieldErrors.map((error) => (
        <li key={error}>{error}</li>
      ))}
    </Description>
  );

  return <StyledAlert description={description} message="Error:" type="error" />;
};
