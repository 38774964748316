import * as Sentry from "@sentry/react";
import { IAuthLogin, IAuthReauth } from "api/auth";
import { applySnapshot, flow, getRoot, Instance, types } from "mobx-state-tree";

import api, { IErrorResponse } from "api";

import { IRootStore } from ".";
import { User } from "./models";

const AuthStore = types
  .model("AuthStore", {
    expiry: types.maybeNull(types.Date),
    token: types.maybeNull(types.string),
    user: types.maybeNull(User),
  })
  .views((self) => ({
    get isLoggedIn() {
      return !!self.token;
    },
  }))
  .actions((self) => ({
    login: flow(function* (email: string, password: string) {
      const response: IAuthLogin = yield api.auth.login(email, password);

      applySnapshot(self, response.data as any);

      getRoot<IRootStore>(self).employees.merge(response.includes.employees);

      api.config.token = response.data.token;

      Sentry.setUser({
        email: response.data.user.email,
        id: response.data.user.id.toString(),
      });
    }),

    logout: flow(function* () {
      try {
        yield api.auth.logout();
      } catch (error: any) {
        // Raises an error when token has expired or has been deleted.
      }

      getRoot<IRootStore>(self).reset();

      Sentry.setUser(null);
    }),

    reauth: flow(function* () {
      try {
        const response: IAuthReauth = yield api.auth.reauth();

        applySnapshot(self, response.data as any);

        const employeeStore = getRoot<IRootStore>(self).employees;

        employeeStore.merge(response.includes.employees);
        employeeStore.mergeEmployments(response.includes.employments);
        employeeStore.mergeEmploymentPositions(response.includes.employmentPositions);

        api.config.token = response.data.token;

        Sentry.setUser({
          email: response.data.user.email,
          id: response.data.user.id.toString(),
        });
      } catch (error: any) {
        const response: IErrorResponse = yield api.handleError(error);

        if (response.code !== "network_error") {
          getRoot<IRootStore>(self).reset();
        }
      }
    }),
  }));

export interface IAuthStore extends Instance<typeof AuthStore> {}

export default AuthStore;
