import React from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router } from "react-router-dom";

import { IRootStore, Provider } from "stores";

import Container from "./Container";

export interface IApplication {
  store: IRootStore;
}

const Application: React.FC<IApplication> = (props) => {
  const { store } = props;

  return (
    <>
      <Helmet defaultTitle="Athena" titleTemplate="%s | Athena" />

      <Provider value={store}>
        <Router>
          <Container />
        </Router>
      </Provider>
    </>
  );
};

export default Application;
