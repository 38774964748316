/* eslint-disable react/jsx-no-useless-fragment */

import { Breadcrumb as AntdBreadcrumb } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import withBreadcrumbs, { BreadcrumbsRoute } from "react-router-breadcrumbs-hoc";
import { Link, RouteComponentProps, useLocation } from "react-router-dom";
import styled from "styled-components";

import { useStore } from "stores";

const StyledBreadcrumb = styled(AntdBreadcrumb)`
  > span:last-of-type::after {
    color: rgba(0, 0, 0, 0.45);
    content: "/";
    margin: 0 8px;
  }
`;

interface IBreadcrumb<T> extends React.FC<RouteComponentProps<T>> {}

const BreadcrumbEmployee: IBreadcrumb<{ employeeId: string }> = observer(({ match }) => {
  const store = useStore();
  const employee = store.employees.get(match.params.employeeId);
  if (!employee) return <>{match.params.employeeId}</>;

  return (
    <>
      {employee.firstName} {employee.lastName}
    </>
  );
});

const BreadcrumbEmployeeNote: IBreadcrumb<{ noteId: string }> = observer(({ match }) => {
  const store = useStore();
  const note = store.employees.getNote(match.params.noteId);
  if (!note) return <>{match.params.noteId}</>;

  return <>{note.id}</>;
});

const BreadcrumbEmployment: IBreadcrumb<{ employmentId: string }> = observer(({ match }) => {
  return <>{match.params.employmentId}</>;
});

const BreadcrumbEmploymentPosition: IBreadcrumb<{ employmentPositionId: string }> = observer(
  ({ match }) => {
    return <>{match.params.employmentPositionId}</>;
  }
);

const BreadcrumbLabor: IBreadcrumb<{ laborId: string }> = observer(({ match }) => {
  const store = useStore();
  const labor = store.labors.get(match.params.laborId);
  if (!labor) return <>{match.params.laborId}</>;

  return <>#{labor.id}</>;
});

const BreadcrumbShop: IBreadcrumb<{ shopId: string }> = observer(({ match }) => {
  const store = useStore();
  const shop = store.shops.get(match.params.shopId);
  if (!shop) return <>{match.params.shopId}</>;

  return <>{shop.displayName}</>;
});

const BreadcrumbShopSchedule: IBreadcrumb<{ scheduleId: string }> = observer(({ match }) => {
  const store = useStore();
  const schedule = store.shops.getSchedule(match.params.scheduleId);
  if (!schedule) return <>{match.params.scheduleId}</>;

  return <>{schedule.weekdayName}</>;
});

const routes: BreadcrumbsRoute[] = [
  { path: "/employees", breadcrumb: "Employees" },
  { path: "/employees/import", breadcrumb: "Import CSV" },
  { path: "/employees/new", breadcrumb: "New" },
  { path: "/employees/positions", breadcrumb: "Positions" },
  { path: "/employees/positions/import", breadcrumb: "Import CSV" },
  { path: "/employees/:employeeId", breadcrumb: BreadcrumbEmployee },
  { path: "/employees/:employeeId/edit", breadcrumb: "Edit" },
  { path: "/employees/:employeeId/ids", breadcrumb: "IDs" },
  { path: "/employees/:employeeId/ids/:employmentId", breadcrumb: BreadcrumbEmployment },
  { path: "/employees/:employeeId/ids/:employmentId/positions", breadcrumb: "Positions" },
  {
    breadcrumb: BreadcrumbEmploymentPosition,
    path: "/employees/:employeeId/ids/:employmentId/positions/:employmentPositionId",
  },
  { path: "/employees/:employeeId/notes", breadcrumb: "Notes" },
  { path: "/employees/:employeeId/notes/new", breadcrumb: "New" },
  { path: "/employees/:employeeId/notes/:noteId", breadcrumb: BreadcrumbEmployeeNote },
  { path: "/employees/:employeeId/notes/:noteId/edit", breadcrumb: "Edit" },
  { path: "/labors", breadcrumb: "Labors" },
  { path: "/labors/:laborId", breadcrumb: BreadcrumbLabor },
  { path: "/shops", breadcrumb: "Shops" },
  { path: "/shops/new", breadcrumb: "New" },
  { path: "/shops/:shopId", breadcrumb: BreadcrumbShop },
  { path: "/shops/:shopId/edit", breadcrumb: "Edit" },
  { path: "/shops/:shopId/schedules", breadcrumb: "Schedules" },
  { path: "/shops/:shopId/schedules/:scheduleId", breadcrumb: BreadcrumbShopSchedule },
  { path: "/shops/:shopId/schedules/:scheduleId/edit", breadcrumb: "Edit" },
];

const unclickableRoutes: string[] = [
  "/employees/:employeeId/ids",
  "/employees/:employeeId/ids/:employmentId/positions",
  "/employees/:employeeId/ids/:employmentId/positions/:employmentPositionId",
  "/employees/positions",
  "/shops/:shopId/schedules",
  "/shops/:shopId/schedules/:scheduleId",
];

interface Props {
  breadcrumbs: any[];
}

const Breadcrumbs: React.FC<Props> = ({ breadcrumbs }) => {
  let { pathname } = useLocation();
  if (pathname.length > 1 && pathname.endsWith("/")) {
    pathname = pathname.slice(0, pathname.length - 1);
  }

  return (
    <StyledBreadcrumb>
      {breadcrumbs
        .filter((breadcrumb: any) => breadcrumb.match.url !== pathname)
        .map((breadcrumb: any) => (
          <AntdBreadcrumb.Item key={breadcrumb.match.url}>
            {unclickableRoutes.includes(breadcrumb.match.path) ? (
              breadcrumb.breadcrumb
            ) : (
              <Link to={breadcrumb.match.url}>{breadcrumb.breadcrumb}</Link>
            )}
          </AntdBreadcrumb.Item>
        ))}
    </StyledBreadcrumb>
  );
};

export default withBreadcrumbs(routes, { disableDefaults: true })(Breadcrumbs as any);
