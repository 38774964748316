import { Button, Result } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import { Loadable } from "components";

interface IErrors404 {
  backUrl?: string;
  buttonTitle?: string;
  isLoading?: boolean;
  recordLabel?: string;
  subtitle?: string;
}

export const Errors404: React.FC<IErrors404> = ({
  backUrl = "/",
  buttonTitle = "Go Back",
  isLoading,
  recordLabel,
  subtitle = "Sorry, the page you visited does not exist.",
}) => {
  if (isLoading) return <Loadable topScreen spinning />;

  return (
    <Result
      status={404}
      title="404"
      subTitle={recordLabel ? `Sorry, the ${recordLabel} you visited does not exist.` : subtitle}
      extra={
        <Link to={backUrl}>
          <Button type="primary">{buttonTitle}</Button>
        </Link>
      }
    />
  );
};
