import { Layout } from "antd";
import { observer } from "mobx-react-lite";
import React, { lazy, Suspense, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import styled from "styled-components";

import { Breadcrumbs, Loadable } from "components";
import { useStore } from "stores";
import { canPayroll, canRead, canWrite } from "utils/permissions";

import Header from "./Header";

const Dashboard = lazy(() => import("scenes/Dashboard"));
const EmployeeNotesDetail = lazy(() => import("scenes/Employees/Notes/scenes/Detail"));
const EmployeeNotesEdit = lazy(() => import("scenes/Employees/Notes/scenes/Edit"));
const EmployeeNotesList = lazy(() => import("scenes/Employees/Notes/scenes/List"));
const EmployeeNotesNew = lazy(() => import("scenes/Employees/Notes/scenes/Create"));
const EmployeesCreate = lazy(() => import("scenes/Employees/scenes/Create"));
const EmployeesDetail = lazy(() => import("scenes/Employees/scenes/Detail"));
const EmployeesEdit = lazy(() => import("scenes/Employees/scenes/Edit"));
const EmployeesImport = lazy(() => import("scenes/Employees/scenes/Import"));
const EmployeesList = lazy(() => import("scenes/Employees/scenes/List"));
const EmploymentPositionsCreate = lazy(() => import("scenes/Employees/Positions/scenes/Create"));
const EmploymentPositionsEdit = lazy(() => import("scenes/Employees/Positions/scenes/Edit"));
const EmploymentPositionsImport = lazy(() => import("scenes/Employees/Positions/scenes/Import"));
const EmploymentsCreate = lazy(() => import("scenes/Employees/Employments/scenes/Create"));
const EmploymentsDetail = lazy(() => import("scenes/Employees/Employments/scenes/Detail"));
const EmploymentsEdit = lazy(() => import("scenes/Employees/Employments/scenes/Edit"));
const Errors404 = lazy(() => import("scenes/Errors/scenes/Errors404"));
const LaborsList = lazy(() => import("scenes/Labors/scenes/List"));
const Login = lazy(() => import("scenes/Login"));
const PayrollsCreate = lazy(() => import("scenes/Payrolls/scenes/Create"));
const ReportsList = lazy(() => import("scenes/Reports/scenes/List"));
const SalesList = lazy(() => import("scenes/Sales/scenes/List"));
const SchedulesSet = lazy(() => import("scenes/Scheduler/Schedules/scenes/Set"));
const ShopSchedulesCreate = lazy(() => import("scenes/Shops/Schedules/scenes/Create"));
const ShopSchedulesEdit = lazy(() => import("scenes/Shops/Schedules/scenes/Edit"));
const ShopsCreate = lazy(() => import("scenes/Shops/scenes/Create"));
const ShopsDetail = lazy(() => import("scenes/Shops/scenes/Detail"));
const ShopsEdit = lazy(() => import("scenes/Shops/scenes/Edit"));
const ShopsList = lazy(() => import("scenes/Shops/scenes/List"));

const StyledContent = styled(Layout.Content)`
  background: none;
  padding: 30px 120px;

  @media (max-width: 1200px) {
    padding: 30px 60px;
  }

  @media (max-width: 900px) {
    padding: 30px;
  }
`;

const StyledLayout = styled(Layout)`
  height: 100%;
  background: none;
`;

const Container: React.FC = observer(() => {
  const store = useStore();
  const { auth, initialized } = store;
  const location = useLocation();

  useEffect(() => {
    store.load();
  }, [store]);

  if (!initialized) return <Loadable topScreen />;

  if (auth.isLoggedIn && auth.user) {
    return (
      <StyledLayout>
        <Header />
        <StyledContent>
          <Breadcrumbs />

          <Suspense fallback={<Loadable topScreen />}>
            <Switch>
              <Route exact path="/">
                <Dashboard />
              </Route>

              {canWrite(auth.user.permissions.rights.employees) && [
                <Route exact key="/employees/new" path="/employees/new">
                  <EmployeesCreate />
                </Route>,

                <Route exact key="/employees/:id/edit" path="/employees/:id/edit">
                  <EmployeesEdit />
                </Route>,
              ]}

              {canWrite(auth.user.permissions.rights.employeeImport) && (
                <Route exact path="/employees/import">
                  <EmployeesImport />
                </Route>
              )}

              {canRead(auth.user.permissions.rights.employees) && [
                <Route exact key="/employees" path="/employees">
                  <EmployeesList />
                </Route>,

                <Route exact key="/employees/:id" path="/employees/:id">
                  <EmployeesDetail />
                </Route>,
              ]}

              {canWrite(auth.user.permissions.rights.employeeNotes) && [
                <Route
                  exact
                  key="/employees/:employeeId/notes/new"
                  path="/employees/:employeeId/notes/new"
                >
                  <EmployeeNotesNew />
                </Route>,

                <Route
                  exact
                  key="/employees/:employeeId/notes/:id/edit"
                  path="/employees/:employeeId/notes/:id/edit"
                >
                  <EmployeeNotesEdit />
                </Route>,
              ]}

              {canRead(auth.user.permissions.rights.employeeNotes) && [
                <Route exact key="/employees/:employeeId/notes" path="/employees/:employeeId/notes">
                  <EmployeeNotesList />
                </Route>,

                <Route
                  exact
                  key="/employees/:employeeId/notes/:id"
                  path="/employees/:employeeId/notes/:id"
                >
                  <EmployeeNotesDetail />
                </Route>,
              ]}

              {canWrite(auth.user.permissions.rights.employeePositions) && [
                <Route exact key="/employees/positions/import" path="/employees/positions/import">
                  <EmploymentPositionsImport />
                </Route>,

                <Route
                  exact
                  key="/employees/:employeeId/ids/:id/positions/new"
                  path="/employees/:employeeId/ids/:id/positions/new"
                >
                  <EmploymentPositionsCreate />
                </Route>,

                <Route
                  exact
                  key="/employees/:employeeId/ids/:employmentId/positions/:id/edit"
                  path="/employees/:employeeId/ids/:employmentId/positions/:id/edit"
                >
                  <EmploymentPositionsEdit />
                </Route>,
              ]}

              {canWrite(auth.user.permissions.rights.employees) && [
                <Route exact key="/employees/:id/ids/new" path="/employees/:id/ids/new">
                  <EmploymentsCreate />
                </Route>,

                <Route
                  exact
                  key="/employees/:employeeId/ids/:id/edit"
                  path="/employees/:employeeId/ids/:id/edit"
                >
                  <EmploymentsEdit />
                </Route>,
              ]}

              {canRead(auth.user.permissions.rights.employeePositions) && [
                <Route
                  exact
                  key="/employees/:employeeId/ids/:id"
                  path="/employees/:employeeId/ids/:id"
                >
                  <EmploymentsDetail />
                </Route>,
              ]}

              {canRead(auth.user.permissions.rights.labors) && [
                <Route exact key="/labors" path="/labors">
                  <LaborsList />
                </Route>,
              ]}

              {canPayroll(auth.user.permissions.rights.payroll) && (
                <Route exact path="/payrolls/new">
                  <PayrollsCreate />
                </Route>
              )}

              {canRead(auth.user.permissions.rights.dashboard) && (
                <Route exact path="/reports">
                  <ReportsList />
                </Route>
              )}

              {canRead(auth.user.permissions.rights.sales) && (
                <Route exact path="/sales">
                  <SalesList />
                </Route>
              )}

              {canRead(auth.user.permissions.rights.workSchedules) && (
                <Route exact path="/schedules">
                  <SchedulesSet />
                </Route>
              )}

              {canWrite(auth.user.permissions.rights.shops) && [
                <Route exact key="/shops/new" path="/shops/new">
                  <ShopsCreate />
                </Route>,

                <Route exact key="/shops/:id/edit" path="/shops/:id/edit">
                  <ShopsEdit />
                </Route>,
              ]}

              {canRead(auth.user.permissions.rights.shops) && [
                <Route exact key="/shops" path="/shops">
                  <ShopsList />
                </Route>,

                <Route exact key="/shops/:id" path="/shops/:id">
                  <ShopsDetail />
                </Route>,
              ]}

              {canWrite(auth.user.permissions.rights.shopHours) && [
                <Route exact key="/shops/:shopId/schedules/new" path="/shops/:shopId/schedules/new">
                  <ShopSchedulesCreate />
                </Route>,

                <Route
                  exact
                  key="/shops/:shopId/schedules/:id/edit"
                  path="/shops/:shopId/schedules/:id/edit"
                >
                  <ShopSchedulesEdit />
                </Route>,
              ]}

              <Route>
                <Errors404 />
              </Route>
            </Switch>
          </Suspense>
        </StyledContent>
      </StyledLayout>
    );
  }

  return (
    <Suspense fallback={<Loadable fullscreen />}>
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>

        <Route>
          <Redirect to={`/?redirectTo=${location.pathname}`} />
        </Route>
      </Switch>
    </Suspense>
  );
});

export default Container;
