import { Pagination as AntdPagination } from "antd";
import React, { ReactNode } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import api from "api";

const Container = styled.div`
  align-items: center;
  align-self: center;
  display: flex;
  padding: 15px 0;
`;

const Space = styled.div`
  flex: 1;
`;

const PageInformation = styled.div`
  margin-right: 10px;
`;

interface IPagination {
  hideInfo?: boolean;
  hideJumper?: boolean;
  label?: string;
  leftView?: ReactNode;
  limit?: number;
  onChange?: (page: number, pageSize?: number) => void;
  page?: number;
  total: number;
}

export const Pagination: React.FC<IPagination> = ({
  hideInfo,
  hideJumper,
  label,
  leftView,
  limit = api.config.defaultLimit,
  onChange,
  page,
  total,
}) => {
  const history = useHistory();
  const location = useLocation();

  if (!page) return null;

  const to = page * limit;
  const from = to - limit + 1;

  const handlePagination = (thePage: number, pageSize?: number) => {
    if (onChange) {
      onChange(thePage, pageSize);
    } else {
      const params = new URLSearchParams(location.search);
      params.set("page", thePage.toString());

      history.push(`${location.pathname}?${params}`);
    }
  };

  return (
    <Container>
      {leftView}

      <Space />

      {!hideInfo && (
        <PageInformation>
          Displaying {label}{" "}
          <strong>
            {Math.min(from, total)} – {Math.min(to, total)}
          </strong>{" "}
          of <strong>{total}</strong> in total
        </PageInformation>
      )}

      <AntdPagination
        current={page}
        onChange={handlePagination}
        pageSize={limit}
        showQuickJumper={!hideJumper}
        total={total}
      />
    </Container>
  );
};
