import { flow, getRoot, Instance, types } from "mobx-state-tree";

import api from "api";
import {
  ILaborsDetail,
  ILaborsEdit,
  ILaborsHoursList,
  ILaborsList,
  ILaborsOutput,
} from "api/labors";
import { mergeToMap } from "utils/stores";
import { sanitizeIdString } from "utils/urls";

import { IRootStore } from ".";
import { ILabor, ILaborHours, Labor, LaborHours } from "./models";

const LaborsStore = types
  .model("LaborsStore", {
    all: types.map(Labor),
    hours: types.map(LaborHours),
    total: types.optional(types.number, 0),
    totalHours: types.optional(types.number, 0),
  })
  .actions((self) => {
    const merge = (data: any[]) => {
      return mergeToMap<ILabor>(data, Labor, self.all);
    };

    const mergeHours = (data: any[]) => {
      return mergeToMap<ILaborHours>(data, LaborHours, self.hours);
    };

    return {
      merge,

      destroy: flow(function* (id: identifierOrString) {
        yield api.labors.destroy(id);

        self.all.delete(id.toString());
      }),

      detail: flow(function* (id: identifierOrString) {
        const response: ILaborsDetail = yield api.labors.detail(id);

        const [labor] = merge([response.data]);

        const employeeStore = getRoot<IRootStore>(self).employees;
        const shopStore = getRoot<IRootStore>(self).shops;

        if (response.includes.employee) {
          employeeStore.merge([response.includes.employee]);
          employeeStore.mergeEmployments([response.includes.employment]);
        }

        employeeStore.mergeEmploymentPositions([response.includes.employmentPosition]);
        shopStore.merge([response.includes.shop]);

        return labor;
      }),

      edit: flow(function* (id: identifierOrString, output: ILaborsOutput) {
        const response: ILaborsEdit = yield api.labors.edit(id, output);

        const [labor] = merge([response.data]);

        const employeeStore = getRoot<IRootStore>(self).employees;
        const shopStore = getRoot<IRootStore>(self).shops;

        if (response.includes.employee) {
          employeeStore.merge([response.includes.employee]);
          employeeStore.mergeEmployments([response.includes.employment]);
        }

        employeeStore.mergeEmploymentPositions([response.includes.employmentPosition]);
        shopStore.merge([response.includes.shop]);

        return labor;
      }),

      list: flow(function* (page = 1, searchParams: any = {}) {
        const response: ILaborsList = yield api.labors.list(page, searchParams);

        self.total = response.count;

        const labors = merge(response.data);

        const employeeStore = getRoot<IRootStore>(self).employees;
        const shopStore = getRoot<IRootStore>(self).shops;

        employeeStore.merge(response.includes.employees);
        employeeStore.mergeEmployments(response.includes.employments);
        employeeStore.mergeEmploymentPositions(response.includes.employmentPositions);
        shopStore.merge(response.includes.shops);

        return labors;
      }),

      listHours: flow(function* (page = 1, searchParams: any = {}) {
        const response: ILaborsHoursList = yield api.labors.hours(page, searchParams);

        self.totalHours = response.count;

        const employeeStore = getRoot<IRootStore>(self).employees;
        const shopStore = getRoot<IRootStore>(self).shops;

        employeeStore.merge(response.includes.employees);
        employeeStore.mergeEmployments(response.includes.employments);
        employeeStore.mergeEmploymentPositions(response.includes.employmentPositions);
        shopStore.merge(response.includes.shops);

        return mergeHours(response.data);
      }),
    };
  })
  .views((self) => ({
    get: (id: identifierOrString) => {
      return self.all.get(sanitizeIdString(id));
    },
  }));

export interface ILaborsStore extends Instance<typeof LaborsStore> {}

export default LaborsStore;
