import { sanitizeId } from "utils/urls";

import base, { IDetail, IList } from "./base";
import { IEmployeesInput, IEmploymentPositionsInput, IEmploymentsInput } from "./employees";
import { IShopsInput } from "./shops";

export interface ILaborsOutput {
  checkDate?: datestamp;
  checkIn?: time | null;
  checkOut?: time | null;
  created?: timestamp;
  employeeName?: string;
  hours?: decimalstring;
  isApproved?: boolean;
  modified?: timestamp;
  notes?: string;
  posIdentifier?: string;
  position?: identifier;
  shop?: identifier;
  stationName?: string;
}

export interface ILaborsInput extends Required<ILaborsOutput> {
  id: identifier;
}

export interface ILaborsDetail extends IDetail<ILaborsInput> {
  includes: {
    employee?: IEmployeesInput;
    employment?: IEmploymentsInput;
    employmentPosition: IEmploymentPositionsInput;
    shop: IShopsInput;
  };
}

export type ILaborsEdit = ILaborsDetail;

export interface ILaborsList extends IList<ILaborsInput> {
  includes: {
    employees: IEmployeesInput[];
    employments: IEmploymentsInput[];
    employmentPositions: IEmploymentPositionsInput[];
    shops: IShopsInput[];
  };
}

export interface ILaborsHoursInput {
  cardTips: decimalstring;
  overtimeHours: number;
  overtimePayRate: decimalstring;
  position: identifier;
  regularHours: number;
  regularPayRate: decimalstring;
  totalHours: number;
}

export interface ILaborsHoursList extends IList<ILaborsHoursInput> {
  includes: {
    employees: IEmployeesInput[];
    employments: IEmploymentsInput[];
    employmentPositions: IEmploymentPositionsInput[];
    shops: IShopsInput[];
  };
}

export interface ILaborsImport {
  rowsInvalid: number;
  rowsSkipped: number;
  employeeNamesInvalid: string[];
  data: ILaborsInput[];
}

export const destroy = (id: identifierOrString) => base.deleteEmpty(`labors/${sanitizeId(id)}/`);
export const detail = (id: identifierOrString) =>
  base.get<ILaborsDetail>(`labors/${sanitizeId(id)}/`);
export const edit = (id: identifierOrString, data: ILaborsOutput) =>
  base.patch<ILaborsEdit>(`labors/${sanitizeId(id)}/`, { json: data });
export const list = (page = 1, searchParams: any = {}) =>
  base.list<ILaborsList>("labors/", page, 0, { searchParams });

export const emailReport = (searchParams: any) =>
  base.postEmpty("labors/email_report/", { searchParams });

export const hours = (page = 1, searchParams: any = {}) =>
  base.list<ILaborsHoursList>("labors/hours/", page, 0, { searchParams });
export const hoursApprove = (searchParams: any) =>
  base.postEmpty("labors/hours_approve/", { searchParams });
export const hoursCsv = (checkWeek: string, shopId: identifierOrString, employments: any[]) =>
  base.postEmpty("labors/hours_csv/", {
    json: { employments },
    searchParams: { checkWeek, shopId },
  });

export const unapproveCheckins = (searchParams: any) =>
  base.postEmpty("labors/checkins_unapprove/", { searchParams });

export interface ILaborsDoubleClock {
  employeeFirstName: string;
  employeeId: identifier;
  employeeLastName: string;
  employmentId: identifier;
  id: identifier;
  isApproved: boolean;
  overlapDate: datestamp;
  overlapEnd: time;
  overlapHours: decimalstring;
  overlapStart: time;
  posIdentifier: string;
  shopId: identifier;
  shopName: string;
}

export interface ILaborsDoubleClocksList {
  count: number;
  data: ILaborsDoubleClock[];
}

export const doubleClocks = (
  checkWeek: string,
  searchParams: { shopId?: identifierOrString } = {}
) => {
  const searchParamsActual: any = { ...searchParams, checkWeek };
  if (!searchParamsActual.shopId) delete searchParamsActual.shopId;

  return base.get<ILaborsDoubleClocksList>("labors/double_clocks/", {
    searchParams: searchParamsActual,
  });
};

export interface ILaborsLeadGap {
  gapDate: datestamp;
  gapEnd: time;
  gapHours: decimalstring;
  gapStart: time;
  id: identifier;
  shop: string;
  shopId: identifier;
}

export interface ILaborsLeadGapsList {
  count: number;
  data: ILaborsLeadGap[];
}

export const leadGaps = (checkWeek: string, searchParams: { shopId?: identifierOrString } = {}) => {
  const searchParamsActual: any = { ...searchParams, checkWeek };
  if (!searchParamsActual.shopId) delete searchParamsActual.shopId;

  return base.get<ILaborsLeadGapsList>("labors/lead_gaps/", {
    searchParams: searchParamsActual,
  });
};

export interface ILaborsLeadOverlap {
  employeeFirstName: string;
  employeeId: identifier;
  employeeLastName: string;
  employmentId: identifier;
  id: identifier;
  isApproved: boolean;
  overlapDate: datestamp;
  overlapEnd: time;
  overlapHours: decimalstring;
  overlapStart: time;
  posIdentifier: string;
  shopId: identifier;
  shopName: string;
}

export interface ILaborsLeadOverlapsList {
  count: number;
  data: ILaborsLeadOverlap[];
}

export const leadOverlaps = (
  checkWeek: string,
  searchParams: { shopId?: identifierOrString } = {}
) => {
  const searchParamsActual: any = { ...searchParams, checkWeek };
  if (!searchParamsActual.shopId) delete searchParamsActual.shopId;

  return base.get<ILaborsLeadOverlapsList>("labors/lead_overlaps/", {
    searchParams: searchParamsActual,
  });
};

export interface ILaborsSync {
  endedAt: timestamp;
  id: identifier;
  isOngoing: boolean;
  startedAt: timestamp;
  week: string;
}

export interface ILaborsSyncStatus {
  data: ILaborsSync;
}

export type ILaborsSyncStart = ILaborsSyncStatus;

export const syncStatus = (id: identifierOrString) =>
  base.get<ILaborsSyncStatus>("labors/sync/", {
    searchParams: { id },
  });

export const syncStart = (checkWeek: string) =>
  base.post<ILaborsSyncStatus>("labors/sync/", {
    json: { checkWeek },
  });
