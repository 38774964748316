import { decamelize } from "humps";
import { format } from "libphonenumber-js";

import { IDate, IDateTime, IDecimal } from "stores";

const numberFormat = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const dateFormat = "MMM D, YYYY";
export const timeFormat = "H:mm A";
export const dateTimeFormat = `${dateFormat}, ${timeFormat}`;

export const capitalize = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const slugify = (value: number | string | Array<number | string>) => {
  let actualValue = value;
  if (Array.isArray(actualValue)) {
    actualValue = actualValue.join(" ");
  }

  const a = "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
  const b = "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnooooooooprrsssssttuuuuuuuuuwxyyzzz------";
  const p = new RegExp(a.split("").join("|"), "g");

  return actualValue
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w-]+/g, "") // Remove all non-word characters
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
};

export const titleize = (value: string) => {
  let result = decamelize(value, { separator: " " }).replace(/\w\S*/g, (text) => {
    return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
  });

  result = result.replace(/\b(ein|futa|id|pos|ssn|sui)\b/gi, (word) => word.toUpperCase());
  result = result.replace("Identifier", "ID");

  return result;
};

export const renderBoolean = (value: boolean) => {
  if (value) {
    return "Yes";
  } else {
    return "—";
  }
};

export const renderDate = (value: IDate) => {
  if (!value) return "—";

  return value.utc().format(dateFormat);
};

export const renderDateTime = (value: IDateTime) => {
  if (!value) return "—";

  return value.format(dateTimeFormat);
};

export const renderDecimal = (value: IDecimal, decimalPlaces = 2) => {
  if (!value) return "—";
  if (value.isZero()) return "—";

  return value.toFixed(decimalPlaces);
};

export const renderMoney = (value: IDecimal) => {
  if (!value) return "—";
  if (!value.isFinite()) return "—";
  if (value.isNaN()) return "—";
  if (value.isZero()) return "—";

  if (value.isNegative()) {
    return `-$${numberFormat.format(value.absoluteValue().toNumber())}`;
  }

  return `$${numberFormat.format(value.toNumber())}`;
};

export const renderPercentage = (value: IDecimal, decimalPlaces = 4) => {
  if (!value) return "—";
  if (!value.isFinite()) return "—";
  if (value.isNaN()) return "—";
  if (value.isZero()) return "—";

  return `${value.toFixed(decimalPlaces)}%`;
};

export const renderPhone = (value?: string) => {
  if (!value) return "—";

  return format(value, "INTERNATIONAL");
};
