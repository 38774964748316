import base, { IDetail } from "./base";
import { IEmployeesInput, IEmploymentPositionsInput, IEmploymentsInput } from "./employees";
import { IShopsInput } from "./shops";

export type IWorkTargetSalesForecastKeys = "1" | "2" | "3" | "4" | "5" | "6" | "7";

export interface IWorkTargetSalesForecast {
  1?: number;
  2?: number;
  3?: number;
  4?: number;
  5?: number;
  6?: number;
  7?: number;
}

export interface IWorkTargetOutput {
  approvedHours?: number | null;
  laborTarget?: decimalstring;
  salesForecast: IWorkTargetSalesForecast;
  shop?: identifier;
  week?: string;
}

export interface IWorkTargetInput extends Required<IWorkTargetOutput> {
  created: timestamp;
  id: string;
  modified: timestamp;
}

export interface IWorkTargetsSet extends IDetail<IWorkTargetInput> {
  includes: {
    shop: IShopsInput;
  };
}

export const workTargetsSet = (output: IWorkTargetOutput) =>
  base.post<IWorkTargetsSet>("scheduler/targets/set/", { json: output });

export interface IWorkSchedulesDates {
  [key: string]: {
    closingHour: time;
    date: datestamp;
    openingHour: time;
    slots: {
      [key: string]: string[];
    };
    weekday: number;
  };
}

export interface IWorkSchedulesOutput {
  dates: IWorkSchedulesDates;
  shop?: identifier;
  week?: string;
}

export interface IWorkSchedulesTotalHours {
  [key: string]: number;
}

export interface IWorkSchedulesTotalLaborCost {
  [key: string]: number;
}

export interface IWorkSchedulesSales {
  [key: string]: number;
}

export interface IWorkSchedulesInput {
  availableSlots: string[];
  dates: {
    approved: IWorkSchedulesDates;
    current: IWorkSchedulesDates;
  };
  id: string;
  isApproved: boolean;
  isSaved: boolean;
  otherHours: IWorkSchedulesTotalHours;
  otherLaborCost: IWorkSchedulesTotalLaborCost;
  sales: IWorkSchedulesSales;
  shop: identifier;
  week: string;
}

export interface IWorkSchedulesList {
  data: IWorkSchedulesInput[];
  includes: {
    employees: IEmployeesInput[];
    employmentPositions: IEmploymentPositionsInput[];
    employments: IEmploymentsInput[];
    target: IWorkTargetInput;
  };
}

export type IWorkSchedulesSet = IWorkSchedulesList;

export const workSchedulesList = (shopId: identifierOrString, week: string) =>
  base.get<IWorkSchedulesList>("scheduler/schedules/", { searchParams: { shop: shopId, week } });

export const workSchedulesSet = (output: IWorkSchedulesOutput) =>
  base.post<IWorkSchedulesSet>("scheduler/schedules/set/", { json: output });

export const workSchedulesCsv = (isApproved: boolean, shopId: identifierOrString, week: string) =>
  base.getEmpty("scheduler/schedules/schedule_csv/", {
    searchParams: { isApproved, shop: shopId, week },
  });

export type IWorkSchedulesApprove = IWorkSchedulesList;
export type IWorkSchedulesUnapprove = IWorkSchedulesList;

export const workSchedulesApprove = (id: string) =>
  base.post<IWorkSchedulesApprove>(`scheduler/schedules/${id}/approve/`);

export const workSchedulesUnapprove = (id: string) =>
  base.post<IWorkSchedulesUnapprove>(`scheduler/schedules/${id}/unapprove/`);
