import { flow, getRoot, Instance, types } from "mobx-state-tree";

import api from "api";
import { IInvoicesList } from "api/sales";
import { mergeToMap } from "utils/stores";
import { sanitizeIdString } from "utils/urls";

import { IRootStore } from ".";
import { IInvoice, Invoice } from "./models";

const SalesStore = types
  .model("SalesStore", {
    invoices: types.map(Invoice),
    total: types.optional(types.number, 0),
  })
  .actions((self) => {
    const mergeInvoices = (data: any[]) => {
      return mergeToMap<IInvoice>(data, Invoice, self.invoices);
    };

    return {
      mergeInvoices,

      list: flow(function* (page = 1, searchParams: any = {}) {
        const response: IInvoicesList = yield api.sales.list(page, searchParams);

        self.total = response.count;

        const employeeStore = getRoot<IRootStore>(self).employees;
        const shopStore = getRoot<IRootStore>(self).shops;

        employeeStore.merge(response.includes.employees);
        employeeStore.mergeEmploymentPositions(response.includes.employmentPositions);
        employeeStore.mergeEmployments(response.includes.employments);
        shopStore.merge(response.includes.shops);

        return mergeInvoices(response.data);
      }),
    };
  })
  .views((self) => ({
    getInvoice: (id: identifierOrString) => {
      return self.invoices.get(sanitizeIdString(id));
    },
  }));

export interface ISalesStore extends Instance<typeof SalesStore> {}

export default SalesStore;
