import { detach, Instance, SnapshotOut, types } from "mobx-state-tree";

import { slugify } from "utils/formatting";

import { Date, DateTime, Money } from "../types";
import { Shop } from "./shop";

export enum EmployeeNoteType {
  General = "General",
  Promotion = "Promotion",
  Discipline = "Discipline",
  Termination = "Termination",
}

export enum EmploymentPositionRole {
  Associate = "Associate",
  ShiftLeader = "Shift Leader",
  AssistantManager = "Assistant Manager",
  Manager = "Manager",
  AreaLeader = "Area Leader",
  OpsDirector = "Ops Director",
  Controller = "Controller",
  Corporate = "Corporate",
}

export enum EmploymentPositionRoleLevel {
  Associate = 1,
  ShiftLeader = 2,
  AssistantManager = 3,
  Manager = 4,
  AreaLeader = 5,
  OpsDirector = 6,
  Controller = 7,
  Corporate = 8,
}

export enum Gender {
  Male = "Male",
  Female = "Female",
}

export enum PayType {
  Salary = "Salary",
  Hourly = "Hourly",
}

export const EmploymentPosition = types
  .model("EmploymentPosition", {
    created: DateTime,
    employment: types.late((): any => types.reference(Employment)),
    id: types.identifierNumber,
    isActive: types.boolean,
    modified: DateTime,
    name: types.string,
    payRate: Money,
    payType: types.enumeration("PayType", Object.values(PayType)),
    posIdentifier: types.string,
    shops: types.array(types.reference(Shop)),
  })
  .actions((self) => ({
    destroy() {
      if (self.employment) {
        self.employment.positions.splice(self.employment.positions.indexOf(self), 1);
      }

      detach(self);
    },
  }))
  .views((self) => ({
    get hasOvertime() {
      return self.payType === "Hourly";
    },

    get slug() {
      return self.id;
    },
  }));

export const Employment = types
  .model("Employment", {
    created: DateTime,
    employee: types.late((): any => types.reference(Employee)),
    employeeIdentifier: types.maybeNull(types.string),
    hireDate: Date,
    id: types.identifierNumber,
    leaveDate: Date,
    modified: DateTime,
    paylocityIdentifier: types.maybeNull(types.string),
    positions: types.array(types.reference(EmploymentPosition)),
    shop: types.reference(Shop),
    status: types.string,
    suiState: types.string,
    terminationDate: Date,
  })
  .actions((self) => ({
    destroy() {
      if (self.employee) {
        self.employee.employments.splice(self.employee.employments.indexOf(self), 1);
      }

      detach(self);
    },
  }))
  .views((self) => ({
    get positionsActiveByName() {
      return self.positions
        .slice()
        .filter((position) => position.isActive)
        .sort((a, b) => (a.name || "").localeCompare(b.name || ""));
    },

    get positionsByPosIdentifier() {
      return self.positions
        .slice()
        .sort((a, b) => (a.posIdentifier || "").localeCompare(b.posIdentifier || ""));
    },

    get displayName() {
      return self.employeeIdentifier || "";
    },

    get slug() {
      return self.id;
    },
  }));

export const EmployeeNote = types
  .model("EmployeeNote", {
    comment: types.string,
    created: DateTime,
    employee: types.late((): any => types.reference(Employee)),
    id: types.identifierNumber,
    modified: DateTime,
    performance: types.number,
    type: types.enumeration("EmployeeNoteType", Object.values(EmployeeNoteType)),
  })
  .actions((self) => ({
    destroy() {
      if (self.employee) self.employee.notes.splice(self.employee.notes.indexOf(self), 1);

      detach(self);
    },
  }));

export const Employee = types
  .model("Employee", {
    birthDate: Date,
    created: DateTime,
    employments: types.array(types.reference(Employment)),
    ethnicity: types.string,
    firstName: types.string,
    fromPaylocity: types.boolean,
    gender: types.string,
    homeAddress1: types.string,
    homeAddress2: types.string,
    homeCity: types.string,
    homeCountry: types.string,
    homeCounty: types.string,
    homeEmail: types.string,
    homeLandlinePhone: types.string,
    homeMobilePhone: types.string,
    homeState: types.string,
    homeZip: types.string,
    i9VerifiedDate: Date,
    id: types.identifierNumber,
    invoiceIdentifier: types.maybeNull(types.string),
    lastName: types.string,
    middleName: types.string,
    modified: DateTime,
    notes: types.array(types.reference(EmployeeNote)),
    shops: types.array(types.reference(Shop)),
    ssn: types.maybeNull(types.string),
    ssnVerifiedDate: Date,
    supervisors: types.late((): any => types.array(types.reference(Employee))),
    uscisNumber: types.string,
    workAddress1: types.string,
    workAddress2: types.string,
    workAuthorization: types.string,
    workCity: types.string,
    workCountry: types.string,
    workCounty: types.string,
    workEmail: types.string,
    workLandlinePhone: types.string,
    workMobilePhone: types.string,
    workState: types.string,
    workZip: types.string,
  })
  .views((self) => ({
    get employmentsByEmployeeIdentifier() {
      return self.employments
        .slice()
        .sort((a, b) => (a.employeeIdentifier || "").localeCompare(b.employeeIdentifier || ""));
    },

    get employmentsByShopName() {
      return self.employments
        .slice()
        .sort((a, b) => (a.shop ? a.shop.name : "").localeCompare(b.shop ? b.shop.name : ""));
    },

    get supervisorsByName() {
      return self.supervisors
        .slice()
        .sort((a: IEmployee, b: IEmployee) => (a.name || "").localeCompare(b.name || ""));
    },

    get name() {
      return `${self.lastName}, ${self.firstName}`;
    },

    get slug() {
      return slugify([self.id, self.lastName, self.firstName]);
    },
  }));

export interface IEmployee extends Instance<typeof Employee> {}
export interface IEmployeeSnapshotOut extends SnapshotOut<typeof Employee> {}

export interface IEmployeeNote extends Instance<typeof EmployeeNote> {}
export interface IEmployeeNoteSnapshotOut extends SnapshotOut<typeof EmployeeNote> {}

export interface IEmployment extends Instance<typeof Employment> {}

export interface IEmploymentPosition extends Instance<typeof EmploymentPosition> {}
